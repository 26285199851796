import { useEffect } from 'react';

import { Navigate, RouteObject, useLocation, useRoutes } from 'react-router-dom';
import { AppRoutesEnum } from './enums/route.enum';
import AboutPage from './pages/About';
import AdminUser from './pages/AdminUser';
import DevelopmentProgram from './pages/DevelopmentProgram';
import EndpointService from './pages/EndpointService';
import AccountPage from './pages/EndpointService/AccountPage';
import EndpointUserDetail from './pages/EndpointService/AccountPage/AccountDetail';
import AwsPage from './pages/EndpointService/Aws';
import PlanPage from './pages/EndpointService/Plan';
import NotFoundPage from './pages/NotFound';
import PrivateWrapper from '~/contexts/PrivateWrapper';
import { WithFirebaseUser } from '~/hooks/with-firebase-auth';
import AuthPage from '~/pages/Auth';

export const routes: RouteObject[] = [
  {
    path: AppRoutesEnum.AUTH,
    element: (
      <WithFirebaseUser>
        <AuthPage />
      </WithFirebaseUser>
    ),
  },
  {
    path: AppRoutesEnum.HOME,
    element: <Navigate to={AppRoutesEnum.DEVELOPMENT_PROGRAM} />,
  },
  {
    path: AppRoutesEnum.DEVELOPMENT_PROGRAM,
    element: <PrivateWrapper />,
    children: [
      {
        index: true,
        element: <DevelopmentProgram />,
      },
    ],
  },
  {
    path: AppRoutesEnum.ENDPOINT_SERVICE,
    element: <PrivateWrapper />,
    children: [
      {
        index: true,
        element: <EndpointService />,
      },
      {
        path: AppRoutesEnum.ENDPOINT_SERVICE_ACCOUNT,
        element: <AccountPage />,
      },
      {
        path: AppRoutesEnum.ENDPOINT_SERVICE_ACCOUNT_DETAIL,
        element: <EndpointUserDetail />,
      },
      {
        path: AppRoutesEnum.ENDPOINT_SERVICE_AWS,
        element: <AwsPage />,
      },
      {
        path: AppRoutesEnum.ENDPOINT_SERVICE_PLAN,
        element: <PlanPage />,
      },
    ],
  },
  {
    path: AppRoutesEnum.ADMIN_USER,
    element: <PrivateWrapper />,
    children: [
      {
        index: true,
        element: <AdminUser />,
      },
    ],
  },
  {
    path: AppRoutesEnum.ABOUT,
    element: <AboutPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export const AppRouter = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return useRoutes(routes);
};
