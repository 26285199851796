import { useCallback, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { Box, Breadcrumbs, Button, Typography } from '@mui/material';
import { GridColDef, GridRowParams, GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import CreateAdminRoleDialog from './AddAdminUserDialog';
import MoreActionButton from './MoreActionButton';
import { stringOperators } from '~/common/filter-common';
import UserLayout from '~/components/AppLayout';
import CustomDataGrid from '~/components/CustomDataGird';
import {
  DATA_GRID_SORTING_ORDER,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ROWS_PER_PAGE_OPTIONS,
} from '~/constants/view';
import { RoleEnums } from '~/enums/common';

import {
  ListAdminUsersSortByEnum,
  OrderByEnum,
  useGetMeQuery,
  useListAdminUsersQuery,
} from '~/graphql/admin-api/types';
import { withApolloClient } from '~/graphql/client';

import { withMe } from '~/hooks/use-me';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const AdminUser = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t, i18n } = useTranslation();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: ListAdminUsersSortByEnum.CreatedAt,
      sort: OrderByEnum.Desc.toLowerCase() as GridSortDirection,
    },
  ]);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    page: DEFAULT_PAGE,
  });

  const {
    data: listAdminUser,
    loading: loadingListAdminUser,
    refetch: refetchListAdminUser,
  } = useListAdminUsersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      sortBy: ListAdminUsersSortByEnum.CreatedAt,
      orderBy: (sortModel[0]?.sort?.toUpperCase() as OrderByEnum) ?? OrderByEnum.Desc,
    },
  });

  const { data: adminUserData, refetch: refetchAdminUserData } = useGetMeQuery({
    fetchPolicy: 'cache-and-network',
  });

  const onOpenCreateUserDialog = useCallback(() => {
    setOpenAddDialog(true);
  }, []);

  const onCloseCreateUserDialog = useCallback(() => {
    setOpenAddDialog(false);
  }, []);

  const onPaginationModelChange = useCallback<
    NonNullable<DataGridProProps['onPaginationModelChange']>
  >(
    (model) => {
      setPaginationModel(model);
    },
    [setPaginationModel],
  );

  const onSortModelChange = useCallback<NonNullable<DataGridProProps['onSortModelChange']>>(
    (model) => {
      setSortModel(model);
    },
    [setSortModel],
  );

  const rows = useMemo(
    () =>
      (listAdminUser?.listAdminUsers?.items || []).map((item, index) => ({
        index: index + 1,
        firebaseUid: item.ownerUid,
        name: item.name,
        email: item.email,
        role: item.role,
        createdAt: item.createdAt,
        adminId: item.adminId,
      })),
    [listAdminUser?.listAdminUsers?.items],
  );
  type Row = (typeof rows)[number];
  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'actions',
        type: 'actions',
        headerName: t('action'),
        width: 70,

        getActions: (params: GridRowParams<Row>) => {
          return [
            <MoreActionButton
              adminUser={params.row}
              refetchAdminUserData={refetchAdminUserData}
              isNotOwner={adminUserData?.getMe?.role !== RoleEnums.OWNER}
              isOwner={params.row.role === RoleEnums.OWNER}
              onRefetchData={refetchListAdminUser}
              id={params.row.adminId as string}
            />,
          ];
        },
      },
      {
        field: 'name',
        headerName: t('name'),
        sortable: false,
        minWidth: 200,
        flex: 1,
        filterOperators: stringOperators,
        renderCell: ({ row }) => <Typography>{row.name || '-'}</Typography>,
      },
      {
        field: 'email',
        headerName: t('email'),
        sortable: false,
        minWidth: 200,
        filterOperators: stringOperators,
      },
      {
        field: 'role',
        headerName: t('admin_user_page.role'),
        sortable: false,
        minWidth: 200,
        filterOperators: stringOperators,
        renderCell: ({ row }) => <Typography className={classes.role}>{row.role}</Typography>,
      },
      {
        field: 'firebaseUid',
        headerName: t('firebase_uid'),
        sortable: false,
        minWidth: 200,
        filterOperators: stringOperators,
        renderCell: ({ row }) => <Typography>{row.firebaseUid || '-'}</Typography>,
      },
      {
        field: ListAdminUsersSortByEnum.CreatedAt,
        headerName: t('created_date'),
        minWidth: 200,
        filterable: false,
        renderCell: ({ row }) => (
          <Typography>{moment(row.createdAt).format(t('date_format'))}</Typography>
        ),
      },
    ],
    [t, adminUserData?.getMe?.role],
  );

  return (
    <UserLayout>
      <div style={{ width: '100%' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Typography>{t('admin_user_page.admin_user_title')}</Typography>
        </Breadcrumbs>
        <Typography className={classes.title}>{t('admin_user_page.admin_user_title')}</Typography>
        {adminUserData?.getMe?.role === RoleEnums.OWNER ? (
          <Box className={classes.addBtn}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              size="small"
              onClick={onOpenCreateUserDialog}
            >
              {t('create_admin_user_dialog.title')}
            </Button>
          </Box>
        ) : (
          <Box sx={{ marginBottom: '30px' }}></Box>
        )}
        <CustomDataGrid
          rows={loadingListAdminUser ? [] : rows}
          columns={columns}
          paginationMode="server"
          sortingMode="server"
          filterMode="client"
          rowCount={listAdminUser?.listAdminUsers?.pagination.totalItems || 0}
          getRowId={(row) => row.index}
          loading={loadingListAdminUser}
          pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          sortModel={sortModel}
          sortingOrder={DATA_GRID_SORTING_ORDER}
          onSortModelChange={onSortModelChange}
          pagination
          disableRowSelectionOnClick
          disableVirtualization
          disableColumnMenu
        />
      </div>
      <CreateAdminRoleDialog
        open={openAddDialog}
        onClose={onCloseCreateUserDialog}
        refetch={refetchListAdminUser}
      />
    </UserLayout>
  );
};

export default compose(withApolloClient, withMe)(AdminUser);
