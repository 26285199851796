import React, { useMemo } from 'react';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import NavigatorMenu from '~/components/NavigatorMenu';
import { AppRoutesEnum } from '~/enums/route.enum';
import { ReactComponent as AwsLogo } from '~/images/aws.svg';
import { StyledComponentProps } from '~/types/material-ui';

import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  isTemporarySideBar: boolean;
  openDrawer: boolean;
  onCloseDrawer: () => void;
}

export type NavigatorItemConfig = {
  path?: string;
  label: string;
  icon?: JSX.Element;
  children?: NavigatorItemConfig[];
};

const SideBar: React.FC<Props> = (props) => {
  const { isTemporarySideBar, openDrawer, onCloseDrawer } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();

  const config = useMemo<NavigatorItemConfig[]>(
    () => [
      {
        path: AppRoutesEnum.DEVELOPMENT_PROGRAM,
        label: t('development_program.development_program_title'),
        icon: <DeveloperBoardOutlinedIcon />,
      },
      {
        label: t('endpoint_service.endpoint_service'),
        icon: <ManageAccountsIcon />,
        children: [
          {
            path: AppRoutesEnum.ENDPOINT_SERVICE_ACCOUNT,
            label: t('account_page.account_page_title'),
            icon: <SwitchAccountIcon />,
          },
          {
            path: AppRoutesEnum.ENDPOINT_SERVICE_AWS,
            label: t('aws_page.aws_page_title'),
            icon: <AwsLogo />,
          },
          {
            path: AppRoutesEnum.ENDPOINT_SERVICE_PLAN,
            label: t('plan'),
            icon: <BackupTableIcon />,
          },
        ],
      },
      {
        path: AppRoutesEnum.ADMIN_USER,
        label: t('admin_user_page.admin_user_title'),
        icon: <AdminPanelSettingsIcon />,
      },
    ],
    [t],
  );

  return (
    <Drawer
      variant={isTemporarySideBar ? 'temporary' : 'persistent'}
      open={isTemporarySideBar ? openDrawer : true}
      onClose={onCloseDrawer}
      classes={{ root: classes.drawer, paper: classes.drawerPaper }}
      data-testid="side-bar"
    >
      <NavigatorMenu
        config={config}
        isTemporarySideBar={isTemporarySideBar}
        onCloseDrawer={onCloseDrawer}
      />
    </Drawer>
  );
};

export default SideBar;
