import { AppRoutesEnum } from '~/enums/route.enum';
import { getEnv } from '~/env';

export const firebaseConfig = {
  firebaseConfig: {
    apiKey: getEnv('REACT_APP_FIREBASE_PUBLIC_API_KEY'),
    authDomain: getEnv('REACT_APP_FIREBASE_AUTH_DOMAIN'),
    databaseURL: getEnv('REACT_APP_FIREBASE_DATABASE_URL'),
    projectId: getEnv('REACT_APP_FIREBASE_PROJECT_ID'),
    storageBucket: getEnv('REACT_APP_FIREBASE_STORAGE_BUCKET'),
    messagingSenderId: getEnv('REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
    appId: getEnv('REACT_APP_FIREBASE_APP_ID'),
  },
  needEmailVerified: true,
  whenAuthed: AppRoutesEnum.HOME,
  whenUnauthed: AppRoutesEnum.AUTH,
};
