import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  authLayoutContent: {
    maxWidth: '600px !important',
  },
  paper: {
    width: '100%',
    textAlign: 'center',
    padding: '50px 10px 40px',
    [theme.breakpoints.up('sm')]: {
      padding: '100px 20px 85px',
    },
  },
  logo: {
    '& img': {
      width: 160,
      [theme.breakpoints.up('sm')]: {
        width: 300,
      },
    },
  },
  description: {
    maxWidth: 400,
    margin: '30px auto 0',
    textAlign: 'left',
  },
  contactButtonWrapper: {
    marginTop: 30,
  },
  backButtonWrapper: {
    marginTop: 20,
  },
}));

export { useStyles };
