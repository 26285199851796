import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  tabs: {
    flex: 1,
    borderBottom: 1,
    borderColor: 'divider',
  },
}));

export { useStyles };
