import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    margin: theme.spacing(1),
    color: theme.colors.black,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2em',
    fontFamily: 'none',
  },
  value: {
    fontSize: 16,
    fontFamily: 'none',
  },
}));

export { useStyles };
