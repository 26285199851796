import i18next from 'i18next';

import en from './locales/en.json';
import ja from './locales/ja.json';
import { LANGUAGE } from '~/constants/language';

const resources = {
  en: {
    translation: en,
  },
  ja: {
    translation: ja,
  },
};

const i18nInstance = i18next.createInstance();

i18nInstance.init({
  resources,
  lng: window.localStorage.getItem('language') || LANGUAGE.EN,
  fallbackLng: LANGUAGE.EN,
  interpolation: {
    escapeValue: false,
  },
});

export default i18nInstance;

declare module 'react-i18next' {
  interface CustomTypeOptions {
    resources: (typeof resources)['en'];
  }
}
