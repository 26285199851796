import React, { PropsWithChildren } from 'react';

// components

import LanguageMenu from '../LanguageMenu';
import { StyledComponentProps } from '~/types/material-ui';

import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles>, PropsWithChildren {
  disableLanguageChangeRedirect?: boolean;
}

const AuthLayout: React.FC<Props> = (props) => {
  const { children } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });

  return (
    <div className={classes.root} data-testid="auth-layout">
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          {children}
          <div className={classes.language}>
            <LanguageMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
