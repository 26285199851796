import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100',
    marginTop: '24px',
  },
}));

export { useStyles };
