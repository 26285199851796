import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: '100%',
    textAlign: 'center',
    padding: '50px 10px 40px',
    [theme.breakpoints.up('sm')]: {
      padding: '100px 20px 85px',
    },
  },
  logo: {
    '& img': {
      width: 160,
      [theme.breakpoints.up('sm')]: {
        width: 300,
      },
    },
  },
  code: {
    marginTop: 24,
  },
  message: {
    margin: '24px auto 0',
    maxWidth: 220,
  },
  loginButtonWrapper: {
    marginTop: 24,
  },
  contactLink: {
    marginTop: 24,
    color: theme.colors.dodgerBlue,
    cursor: 'pointer',
  },
}));

export { useStyles };
