import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { GetEndpointServiceUserQuery } from '~/graphql/admin-api/types';
import { withApolloClient } from '~/graphql/client';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  userInfoData?: GetEndpointServiceUserQuery;
}

const AccountDetailAws = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { userInfoData } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Box className={classes.wrapper}>
        <Typography className={classes.title}>{t('aws_account_id')} :</Typography>
        <Typography className={classes.infomation}>
          {userInfoData?.getEndpointServiceUser.aws?.awsAccountId ?? '-'}
        </Typography>
      </Box>
      <Box className={classes.wrapper}>
        <Typography className={classes.title}>{t('region_name')} :</Typography>
        <Typography className={classes.infomation}>
          {userInfoData?.getEndpointServiceUser?.aws?.regionName ?? '-'}
        </Typography>
      </Box>
      <Box className={classes.wrapper}>
        <Typography className={classes.title}>{t('api_key_id')} :</Typography>
        <Typography className={classes.infomation}>
          {userInfoData?.getEndpointServiceUser?.aws?.apiKeyId ?? '-'}
        </Typography>
      </Box>
      <Box className={classes.wrapper}>
        <Typography className={classes.title}>{t('api_key_value')} :</Typography>
        <Typography className={classes.infomation}>
          {userInfoData?.getEndpointServiceUser.aws?.apiKeyValue ?? '-'}
        </Typography>
      </Box>
    </div>
  );
};

export default compose(withApolloClient)(AccountDetailAws);
