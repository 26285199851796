import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: '100%',
    textAlign: 'center',
    padding: '50px 10px 40px',
    [theme.breakpoints.up('sm')]: {
      padding: '100px 20px 85px',
    },
  },
  logo: {
    '& img': {
      width: 160,
      [theme.breakpoints.up('sm')]: {
        width: 300,
      },
    },
  },
  title: {
    fontSize: 8,
    [theme.breakpoints.up('sm')]: {
      fontSize: 'unset',
    },
  },
  emailConfirmationWaring: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  btnLogout: {
    margin: theme.spacing(0.5, 0, 2, 0),
    textTransform: 'uppercase',
  },
}));

export { useStyles };
