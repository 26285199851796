import { IsNotEmpty, IsEmail, Length } from 'class-validator';
import { TFunction } from 'i18next';
import { validateObject } from '~/common/validator/class-validator-helper';
import { EMAIL_MAX_LENGTH, EMAIL_MIN_LENGTH } from '~/constants/validation';
import { AdminUserRoleEnum } from '~/graphql/admin-api/types';

export class FormValues {
  role: string;

  @IsEmail(undefined, {
    context: {
      i18n: {
        key: 'form_validation.isEmail',
      },
    },
  })
  @Length(EMAIL_MIN_LENGTH, EMAIL_MAX_LENGTH, {
    context: {
      i18n: {
        key: 'form_validation.length',
        args: {
          min: EMAIL_MIN_LENGTH,
          max: EMAIL_MAX_LENGTH,
        },
      },
    },
  })
  @IsNotEmpty({
    context: {
      i18n: {
        key: 'form_validation.isNotEmpty',
      },
    },
  })
  email: string;
}

export const initialValues: FormValues = {
  email: '',
  role: AdminUserRoleEnum.Administrator,
};

export const validateForm = (t: TFunction) => async (values) =>
  await validateObject(t, Object.assign(new FormValues(), values));
