import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  list: {},
  listItem: {
    width: 180,
    height: 48,
    cursor: 'pointer',
  },
}));

export { useStyles };
