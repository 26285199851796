import React from 'react';
import { generateLicense, LicenseInfo } from '@mui/x-license-pro';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

LicenseInfo.setLicenseKey(
  generateLicense({
    expiryDate: new Date(4000, 10, 10),
    orderNumber: '1',
    licensingModel: 'subscription',
    scope: 'premium',
  }),
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
