import React, { useMemo } from 'react';

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Box, Divider, ListItem, ListItemIcon, Typography } from '@mui/material';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';

import { useTranslation } from 'react-i18next';

import LanguageMenu from '../LanguageMenu';
import NavigatorItem, { NavigatorItemConfig } from './NavigatorItem';
import { useLogout } from '~/hooks/use-logout';
import { StyledComponentProps } from '~/types/material-ui';

import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  config: NavigatorItemConfig[];
  isTemporarySideBar?: boolean;
  onCloseDrawer?: () => void;
}

const NavigatorMenu: React.FC<Props> = (props) => {
  const { config, isTemporarySideBar, onCloseDrawer = () => {} } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const logout = useLogout();
  const { t } = useTranslation();

  const renderNavigatorItems = useMemo(
    () => config.map((item, index) => <NavigatorItem item={item} key={index} />),
    [config],
  );

  return (
    <List sx={{ paddingBottom: 0 }}>
      <Toolbar />
      <Box
        className={classes.sideBar}
        sx={{ height: !isTemporarySideBar ? 'calc(100vh - 104px)' : 'unset' }}
      >
        <Box className={classes.navigatorItem}>
          {renderNavigatorItems}
          <ListItem className={classes.listItem} onClick={logout}>
            <ListItemIcon className={classes.listItemIcon}>
              <LogoutOutlinedIcon />
            </ListItemIcon>
            <Typography className={classes.logOutBtn}>{t('logout')}</Typography>
          </ListItem>
          {isTemporarySideBar && (
            <Box>
              <Divider />
              <ListItem>
                <LanguageMenu />
              </ListItem>
            </Box>
          )}
        </Box>
      </Box>
    </List>
  );
};

export default NavigatorMenu;
