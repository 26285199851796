import { makeStyles } from 'tss-react/mui';
import { closeDialogButton } from '~/styles/common';

const useStyles = makeStyles()((theme) => ({
  closeIcon: {
    ...closeDialogButton(theme),
  },
  dialog: {
    padding: 0,
  },
  divider: {
    margin: theme.spacing(0, 3, 0, 3),
  },
  item: {
    fontWeight: 500,
    marginLeft: '16px',
  },
}));

export { useStyles };
