export enum AppRoutesEnum {
  HOME = '/',
  AUTH = '/auth',
  ABOUT = '/about',
  DEVELOPMENT_PROGRAM = '/development-program',
  ENDPOINT_SERVICE = '/endpoint-service',
  ENDPOINT_SERVICE_ACCOUNT = '/endpoint-service/account',
  ENDPOINT_SERVICE_ACCOUNT_DETAIL = '/endpoint-service/account/:id',
  ENDPOINT_SERVICE_AWS = '/endpoint-service/aws',
  ENDPOINT_SERVICE_PLAN = '/endpoint-service/plan',
  ADMIN_USER = '/admin-user',
  UNAUTHENTICATED = '/unauthenticated',
}
