import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const AboutPage = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>About</h1>
      <p className={classes.value}>Version : {process.env.REACT_APP_VERSION}</p>
    </div>
  );
};

export default AboutPage;
