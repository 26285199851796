import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    marginTop: '24px',
    backgroundColor: theme.colors.white,
    borderRadius: '8px',
    border: '1px solid #D7D7D7',
    '.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within':
      {
        outline: ' none',
      },
    '.MuiDataGrid-columnHeaders': {
      borderBottom: `1px solid ${theme.colors.gainsboro}`,
    },
    '.MuiDataGrid-footerContainer': {
      borderTop: `1px solid ${theme.colors.gainsboro}`,
    },
    '.MuiDataGrid-root': {
      borderRadius: '8px',
      border: `1px solid ${theme.colors.gainsboro}`,
      '.MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-iconButtonContainer .MuiBadge-root .MuiBadge-badge':
        {
          display: 'none',
        },
    },
    '.MuiPaper-root': {
      overflow: 'hidden',
      boxShadow: 'none',
    },
    '.MuiButtonBase-root.Mui-expanded': {
      backgroundColor: '#0367df14',
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    padding: '12px 16px',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '8px',
  },
  title: {
    fontSize: 16,
    minWidth: 210,
    [theme.breakpoints.down('md')]: {
      minWidth: 140,
    },
    fontWeight: 500,
  },
  infomation: {
    fontSize: 16,
  },
  pageTitle: {
    fontSize: '24px',
    textTransform: 'uppercase',
    marginBottom: '30px',
  },
  viewAll: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.colors.deepBlue,
  },
  region: {
    display: 'flex',
    padding: 0,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    borderBottom: '1px solid #D7D7D7',
  },

  tableHeader: {
    margin: '10px 0',
    fontWeight: 500,
  },
  regionName: {
    minWidth: 168,
    [theme.breakpoints.down('md')]: {
      minWidth: 120,
    },
  },
  regionTitle: {
    color: theme.colors.deepBlue,
    fontWeight: 500,
  },

  apiSection: {
    margin: '20px 0',
  },
  api: {
    fontSize: 14,
    margin: '4px 0',
  },
  apiDetail: {
    fontSize: 14,
  },
  apiId: {
    margin: '10px 0',
  },
  collapse: {
    flexDirection: 'row-reverse',
    '.MuiBox-root': {
      padding: '0 16px',
    },
  },
  collapseTitle: {
    display: 'flex',
    marginLeft: '16px',
  },
}));

export { useStyles };
