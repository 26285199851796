import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  AdminUser,
  DeleteAdminUserDocument,
  useDeleteAdminUserMutation,
} from '~/graphql/admin-api/types';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  adminUser: AdminUser;
  open: boolean;
  onClose: () => void;
  refech: () => Promise<any>;
}

const DeleteAdminUserDialog: React.FC<Props> = (props) => {
  const { open, adminUser, refech, onClose } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteAdminUser] = useDeleteAdminUserMutation({
    refetchQueries: [DeleteAdminUserDocument],
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      await deleteAdminUser({
        variables: {
          input: {
            adminId: adminUser.adminId as string,
          },
        },
      });
      onClose();
      refech();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [deleteAdminUser, enqueueSnackbar, adminUser.adminId, onClose]);

  return (
    <Dialog fullWidth open={open} data-testid="delete-admin-user-dialog">
      <IconButton onClick={onClose} className={classes.closeIcon} data-testid="close-button">
        <img src="/images/close_icon.svg" alt="" />
      </IconButton>
      <DialogTitle>
        <Typography variant="h5" color="primary" component="p">
          {t('delete_admin_user_dialog.title')}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {t('delete_admin_user_dialog.description', { email: adminUser.email })}
        </Typography>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogActions className={classes.dialogActions}>
        <Button color="primary" variant="outlined" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={loading}
          endIcon={loading && <CircularProgress size={20} color="inherit" />}
          onClick={onSubmit}
        >
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAdminUserDialog;
