import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export { useStyles };
