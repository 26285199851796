import { makeStyles } from 'tss-react/mui';
import { closeDialogButton } from '~/styles/common';

const useStyles = makeStyles()((theme) => ({
  closeIcon: {
    ...closeDialogButton(theme),
  },
  divider: {
    margin: theme.spacing(0, 3, 2, 3),
  },
  dialogActions: {
    rowGap: theme.spacing(2),
    flexWrap: 'wrap-reverse',
    justifyContent: 'end',
  },
  description: {
    textAlign: 'center',
    marginTop: 8,
  },
}));

export { useStyles };
