import React, { useCallback } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../AuthLayout';
import { AppRoutesEnum } from '~/enums/route.enum';
import { useLogout } from '~/hooks/use-logout';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const UnauthenticatedError: React.FC<Props> = (props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });

  const { t } = useTranslation();
  const logout = useLogout();
  const onLogout = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <AuthLayout classes={{ content: classes.authLayoutContent }}>
      <Paper className={classes.paper}>
        <div className={classes.logo}>
          <img src="/images/logo.svg" alt="" />
        </div>
        <div className={classes.description}>
          <Typography variant="body1">{t('unauthenticated_error_page.description')}</Typography>
        </div>
        <div className={classes.backButtonWrapper}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={onLogout}
          >
            {t('back')}
          </Button>
        </div>
      </Paper>
    </AuthLayout>
  );
};

export default UnauthenticatedError;
