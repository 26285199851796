import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { useTranslation } from 'react-i18next';
import { FormValues, initialValues, validateForm } from './form';
import {
  CreateAdminUserDocument,
  AdminUserRoleEnum,
  useCreateAdminUserMutation,
} from '~/graphql/admin-api/types';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  refetch: () => Promise<any>;
  open: boolean;
  onClose: () => void;
}

const CreateAdminRoleDialog: React.FC<Props> = (props) => {
  const { open, refetch, onClose } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [addAdminUser] = useCreateAdminUserMutation({
    refetchQueries: [CreateAdminUserDocument],
  });
  const roleOptions = [
    {
      value: AdminUserRoleEnum.Administrator,
      label: AdminUserRoleEnum.Administrator,
    },
  ];

  const onCloseAndResetForm = useCallback(() => {
    formik.resetForm();
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await addAdminUser({
          variables: {
            input: {
              email: values.email,
              role: values.role as AdminUserRoleEnum,
            },
          },
        });
        enqueueSnackbar(t('add_user_successful'), { variant: 'success' });
        onCloseAndResetForm();
        refetch();
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [onCloseAndResetForm, enqueueSnackbar],
  );
  const formik = useFormik<FormValues>({
    initialValues: initialValues,
    validate: validateForm(t),
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <Dialog fullWidth open={open} data-testid="create-admin-user-dialog">
      <IconButton onClick={onCloseAndResetForm} className={classes.closeIcon}>
        <img src="/images/close_icon.svg" alt="" />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Typography variant="h5" color="primary" component="p">
            {t('create_admin_user_dialog.title')}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            {t('create_admin_user_dialog.description')}
          </Typography>
        </DialogTitle>
        <Divider className={classes.divider} />
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="email"
                label={t('email')}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.errors.email && formik.touched.email && formik.errors.email}
                error={Boolean(formik.errors.email) && formik.touched.email}
                FormHelperTextProps={{ autoFocus: false }}
                color="primary"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                name="role"
                label={t('admin_user_page.role')}
                value={formik.values.role}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.errors.role && formik.touched.role && formik.errors.role}
                error={Boolean(formik.errors.role) && formik.touched.role}
                color="primary"
                variant="outlined"
                fullWidth
              >
                {roleOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button color="primary" variant="outlined" onClick={onCloseAndResetForm}>
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
            endIcon={formik.isSubmitting && <CircularProgress size={20} color="inherit" />}
          >
            {t('add')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateAdminRoleDialog;
