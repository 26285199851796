import { useCallback, useMemo, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { GridColDef, GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { stringOperators } from '~/common/filter-common';
import UserLayout from '~/components/AppLayout';
import CustomDataGrid from '~/components/CustomDataGird';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, ROWS_PER_PAGE_OPTIONS } from '~/constants/view';
import {
  ListPlansSortByEnum,
  OrderByEnum,
  Period,
  useListEndpointServicePlansQuery,
} from '~/graphql/admin-api/types';
import { withApolloClient } from '~/graphql/client';
import { withMe } from '~/hooks/use-me';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const PlanPage = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t, i18n } = useTranslation();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    page: DEFAULT_PAGE,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: ListPlansSortByEnum.CreatedAt,
      sort: OrderByEnum.Desc.toLowerCase() as GridSortDirection,
    },
  ]);

  const onPaginationModelChange = useCallback<
    NonNullable<DataGridProProps['onPaginationModelChange']>
  >(
    (model) => {
      setPaginationModel(model);
    },
    [setPaginationModel],
  );

  const onSortModelChange = useCallback<NonNullable<DataGridProProps['onSortModelChange']>>(
    (model) => {
      setSortModel(model);
    },
    [setSortModel],
  );
  const getPeriodI18n = (period: Period) => {
    switch (period) {
      case Period.Day:
        return t('day');
      case Period.Month:
        return t('month');
      case Period.Week:
        return t('week');
      default:
        return '-';
    }
  };

  const {
    data: listPlanData,
    loading: loadingListPlan,
    refetch: refetchListPlan,
  } = useListEndpointServicePlansQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      sortBy: ListPlansSortByEnum.CreatedAt,
      orderBy: (sortModel[0]?.sort?.toUpperCase() as OrderByEnum) ?? OrderByEnum.Desc,
    },
  });

  const rows = useMemo(
    () =>
      (listPlanData?.listEndpointServicePlans.items || []).map((item, index) => ({
        id: item._id,
        endpointLimitCount: item.endpointLimitCount,
        plan: item.plan,
        requestLimitCount: item.requestLimitCount,
        requestLimitPeriod: item.requestLimitPeriod,
      })),
    [listPlanData?.listEndpointServicePlans.items],
  );

  type Row = (typeof rows)[number];
  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'id',
        sortable: false,
        headerName: 'ID',
        minWidth: 50,
        flex: 1,
        filterOperators: stringOperators,
      },
      {
        field: 'plan',
        headerName: t('plan'),
        sortable: false,
        minWidth: 200,
        filterOperators: stringOperators,
        renderCell: ({ value, row }) => {
          return (
            <Typography>
              {t(`endpoint_plan_name_enum.${row.plan?.toLowerCase()}`) || '-'}
            </Typography>
          );
        },
      },
      {
        field: 'requestLimitCount',
        headerName: t('plan_page.request_limit'),
        sortable: false,
        minWidth: 200,
        filterOperators: stringOperators,
      },
      {
        field: 'requestLimitPeriod',
        headerName: t('plan_page.request_limit_period'),
        sortable: false,
        minWidth: 200,
        filterOperators: stringOperators,
        renderCell: ({ value, row }) => {
          return <Typography>{getPeriodI18n(row.requestLimitPeriod)}</Typography>;
        },
      },
    ],
    [t],
  );

  return (
    <UserLayout>
      <div style={{ width: '100%' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Typography>{t('endpoint_service.endpoint_service')}</Typography>
          <Typography>{t('plan')}</Typography>
        </Breadcrumbs>
        <Typography className={classes.title}>{t('plan')}</Typography>
        <div style={{ marginTop: '16px' }}>
          <CustomDataGrid
            rows={loadingListPlan ? [] : rows}
            columns={columns}
            paginationMode="server"
            sortingMode="server"
            rowCount={listPlanData?.listEndpointServicePlans.pagination.totalItems || 0}
            getRowId={(row) => row.id}
            loading={loadingListPlan}
            pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            pagination
            disableRowSelectionOnClick
            disableVirtualization
            disableColumnMenu
          />
        </div>
      </div>
    </UserLayout>
  );
};

export default compose(withApolloClient, withMe)(PlanPage);
