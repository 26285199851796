import React, { useCallback } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavigatorItemConfig } from '../NavigatorItem';
import { AppRoutesEnum } from '~/enums/route.enum';
import { StyledComponentProps } from '~/types/material-ui';

import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  item: NavigatorItemConfig;
}

const NavigatorItemChildren: React.FC<Props> = (props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { item } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    if (item.path) {
      localStorage.setItem('childNavigator', item.path);
      navigate(item.path);
    }
  }, [item, navigate]);

  const isSelected =
    item.path?.length === 1
      ? location.pathname === AppRoutesEnum.HOME
      : new RegExp(`^${item.path}`, 'g').test(location.pathname);

  return (
    <>
      <ListItemButton
        selected={isSelected}
        className={`${classes.listItem} ${isSelected ? classes.selectedItem : ''}`}
        onClick={handleClick}
      >
        {!!item.icon && (
          <ListItemIcon className={`${classes.listItemIcon} ${isSelected}`}>
            {item.icon}
          </ListItemIcon>
        )}
        <ListItemText className={classes.listItemLabel} primary={item.label} />
      </ListItemButton>
    </>
  );
};

export default NavigatorItemChildren;
