import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import LoaderCenter from '~/components/LoaderCenter';
import { GetEndpointServiceUserQuery } from '~/graphql/admin-api/types';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  userInfoData?: GetEndpointServiceUserQuery;
}

const AccountDetailInfo = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { userInfoData } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      {!userInfoData ? (
        <LoaderCenter />
      ) : (
        <>
          <Box className={classes.wrapper}>
            <Typography className={classes.title}>{t('account_id')} :</Typography>
            <Typography className={classes.infomation}>
              {userInfoData?.getEndpointServiceUser.accountId ?? '-'}
            </Typography>
          </Box>
          <Box className={classes.wrapper}>
            <Typography className={classes.title}>{t('firebase_uid')} :</Typography>
            <Typography className={classes.infomation}>
              {userInfoData?.getEndpointServiceUser.ownerUid ?? '-'}
            </Typography>
          </Box>
          <Box className={classes.wrapper}>
            <Typography className={classes.title}>{t('name')} :</Typography>
            <Typography className={classes.infomation}>
              {userInfoData?.getEndpointServiceUser.name ?? '-'}
            </Typography>
          </Box>
          <Box className={classes.wrapper}>
            <Typography className={classes.title}>{t('plan')} :</Typography>
            <Typography className={classes.plan}>
              {userInfoData?.getEndpointServiceUser.plan ?? '-'}
            </Typography>
          </Box>
          <Box className={classes.wrapper}>
            <Typography className={classes.title}>{t('created_date')} :</Typography>
            <Typography className={classes.infomation}>
              {userInfoData?.getEndpointServiceUser.createdAt
                ? moment(userInfoData?.getEndpointServiceUser.createdAt).format(t('date_format'))
                : '-'}
            </Typography>
          </Box>
        </>
      )}
    </div>
  );
};

export default AccountDetailInfo;
