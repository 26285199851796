import { IsNotEmpty } from 'class-validator';
import { TFunction } from 'i18next';
import { validateObject } from '~/common/validator/class-validator-helper';

export class FormValues {
  role: string;
}

export const validateForm = (t: TFunction) => async (values) =>
  await validateObject(t, Object.assign(new FormValues(), values));
