// React, Redux
import React, { useCallback } from 'react';

// Material UI
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Popover, { PopoverProps } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
// Components
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';
import { useLogout } from '~/hooks/use-logout';
import { StyledComponentProps } from '~/types/material-ui';

import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  open: boolean;
  onClose: () => void;
  anchorEl: PopoverProps['anchorEl'];
  popoverProps?: PopoverProps;
}

const UserPopover: React.FC<Props> = (props) => {
  const user = firebase.auth().currentUser;
  const { open, onClose, popoverProps, anchorEl } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const logout = useLogout();
  const { t } = useTranslation();
  const onLogout = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      {...popoverProps}
      classes={{ paper: classes.popoverPaper }}
      data-testid="user-popover"
    >
      <div className={classes.userInfo}>
        <img
          className={classes.avatarImg}
          src={user?.photoURL || '/images/avatar-default.svg'}
          alt=""
        />
        <div>
          <Typography variant="overline" component="div" className={classes.displayNameText}>
            {user?.displayName}
          </Typography>
          <Typography className={classes.email} variant="caption" component="div">
            {user?.email}
          </Typography>
        </div>
      </div>
      <Divider />
      <List className={classes.list}>
        <ListItem button className={classes.listItem} onClick={onLogout}>
          <Typography>{t('logout')}</Typography>
        </ListItem>
      </List>
    </Popover>
  );
};

export default UserPopover;
