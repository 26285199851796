import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = { context: { apiName: 'admin-api' } } as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  /** Account id */
  adminId?: Maybe<Scalars['String']>;
  /** Created At */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Email */
  email: Scalars['String'];
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Firebase UID */
  ownerUid?: Maybe<Scalars['String']>;
  /** Role */
  role?: Maybe<Scalars['String']>;
};

export enum AdminUserRoleEnum {
  Administrator = 'ADMINISTRATOR',
  Owner = 'OWNER',
}

export enum AwsRegion {
  Osaka = 'OSAKA',
  Tokyo = 'TOKYO',
}

export type CreateAdminInputDto = {
  /** Email */
  email: Scalars['String'];
  /** Role */
  role?: InputMaybe<AdminUserRoleEnum>;
};

export type DeleteAdminUserInput = {
  /** Admin ID */
  adminId: Scalars['String'];
};

export type DevProgramAccount = {
  __typename?: 'DevProgramAccount';
  /** Account id */
  accountId: Scalars['String'];
  /** Created At */
  createdAt: Scalars['DateTime'];
  /** Accept term of service */
  isAcceptTermOfService: Scalars['Boolean'];
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Firebase UID */
  ownerUid: Scalars['String'];
  /** Status */
  status: DevProgramMemberStatus;
};

export enum DevProgramMemberStatus {
  Business = 'BUSINESS',
  Enterprise = 'ENTERPRISE',
  Premium = 'PREMIUM',
  Profressional = 'PROFRESSIONAL',
  Starter = 'STARTER',
}

export type Endpoint = {
  __typename?: 'Endpoint';
  /** API ID */
  apiId: Scalars['String'];
  /** Endpoint Id */
  endpointId: Scalars['String'];
  /** Endpoint name */
  name: Scalars['String'];
  /** Resource ID */
  resourceId: Scalars['String'];
  /** Endpoint status */
  status: EndpointStatus;
};

export type EndpointServiceApi = {
  __typename?: 'EndpointServiceApi';
  /** API ID */
  apiId?: Maybe<Scalars['String']>;
  /** Authorizer ID */
  authorizerId?: Maybe<Scalars['String']>;
  /** Endpoint Type */
  endpointType?: Maybe<EndpointType>;
  /** Domain connected */
  isDomainConnected?: Maybe<Scalars['Boolean']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Root resource ID */
  rootResourceId?: Maybe<Scalars['String']>;
  /** URL path */
  urlPath?: Maybe<Scalars['String']>;
};

export type EndpointServiceAws = {
  __typename?: 'EndpointServiceAws';
  /** Account ID */
  accountId: Scalars['String'];
  /** Account Name */
  accountName: Scalars['String'];
  /** Created At */
  createdAt: Scalars['DateTime'];
  /** Available */
  isAvailable: Scalars['Boolean'];
  /** List Region on AWS account */
  regions?: Maybe<Array<EndpointServiceRegion>>;
  /** Last updated at */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EndpointServiceRegion = {
  __typename?: 'EndpointServiceRegion';
  /** Key IDs */
  apis?: Maybe<Array<EndpointServiceApi>>;
  /** Available */
  isAvailable: Scalars['Boolean'];
  /** Key IDs */
  regionName: AwsRegion;
  /** Usage Plan */
  usagePlans?: Maybe<Array<EndpointServiceUsagePlan>>;
};

export type EndpointServiceUsagePlan = {
  __typename?: 'EndpointServiceUsagePlan';
  /** API IDs */
  apiIds?: Maybe<Array<Scalars['String']>>;
  /** Key IDs */
  keyIds?: Maybe<Array<Scalars['String']>>;
  /** Name */
  name?: Maybe<PlanName>;
  /** Usage Plan ID */
  usagePlanId?: Maybe<Scalars['String']>;
};

export enum EndpointStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export enum EndpointType {
  HttpHeader = 'HTTP_HEADER',
  HttpParameter = 'HTTP_PARAMETER',
  WsHeader = 'WS_HEADER',
  WsParameter = 'WS_PARAMETER',
}

export type EndpointUser = {
  __typename?: 'EndpointUser';
  /** Account ID */
  accountId: Scalars['String'];
  /** User AWS */
  aws?: Maybe<UserAwsDto>;
  /** Created At */
  createdAt: Scalars['DateTime'];
  /** List Endpoint on User */
  endpoints?: Maybe<Array<Endpoint>>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Firebase UID */
  ownerUid: Scalars['String'];
  /** Plan */
  plan?: Maybe<Scalars['String']>;
};

export type GetEndpointServiceUserInput = {
  /** endpoint id */
  ownerUid: Scalars['String'];
};

export type ListAdminUsersResponse = {
  __typename?: 'ListAdminUsersResponse';
  /** Data */
  items: Array<AdminUser>;
  /** Pagination */
  pagination: Pagination;
  /** Sorting */
  sorting: ListAdminUsersSortingOptionsResponse;
};

export enum ListAdminUsersSortByEnum {
  CreatedAt = 'CREATED_AT',
}

export type ListAdminUsersSortingOptionsResponse = {
  __typename?: 'ListAdminUsersSortingOptionsResponse';
  /** Sort order */
  orderBy?: Maybe<OrderByEnum>;
  /** Sort by */
  sortBy?: Maybe<ListAdminUsersSortByEnum>;
};

export type ListDevProgramAccountSortResponse = {
  __typename?: 'ListDevProgramAccountSortResponse';
  /** Sort order */
  orderBy?: Maybe<OrderByEnum>;
  /** Sort by */
  sortBy?: Maybe<ListDevProgramAccountsSortByEnum>;
};

export type ListDevProgramAccountsResponse = {
  __typename?: 'ListDevProgramAccountsResponse';
  /** Data */
  items: Array<DevProgramAccount>;
  /** Pagination */
  pagination: Pagination;
  /** Sorting */
  sorting: ListDevProgramAccountSortResponse;
};

export enum ListDevProgramAccountsSortByEnum {
  CreatedAt = 'CREATED_AT',
}

export type ListEndpointUsersResponse = {
  __typename?: 'ListEndpointUsersResponse';
  /** Data */
  items: Array<EndpointUser>;
  /** Pagination */
  pagination: Pagination;
  /** Sorting */
  sorting: ListEndpointUsersSortResponse;
};

export enum ListEndpointUsersSortByEnum {
  CreatedAt = 'CREATED_AT',
}

export type ListEndpointUsersSortResponse = {
  __typename?: 'ListEndpointUsersSortResponse';
  /** Sort order */
  orderBy?: Maybe<OrderByEnum>;
  /** Sort by */
  sortBy?: Maybe<ListEndpointUsersSortByEnum>;
};

export type ListPlansResponse = {
  __typename?: 'ListPlansResponse';
  /** Data */
  items: Array<Plan>;
  /** Pagination */
  pagination: Pagination;
  /** Sorting */
  sorting: ListPlansSortResponse;
};

export enum ListPlansSortByEnum {
  CreatedAt = 'CREATED_AT',
}

export type ListPlansSortResponse = {
  __typename?: 'ListPlansSortResponse';
  /** Sort order */
  orderBy?: Maybe<OrderByEnum>;
  /** Sort by */
  sortBy?: Maybe<ListPlansSortByEnum>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAdminUser?: Maybe<AdminUser>;
  deleteAdminUser?: Maybe<Scalars['Boolean']>;
  updateAdminUser?: Maybe<AdminUser>;
};

export type MutationCreateAdminUserArgs = {
  input: CreateAdminInputDto;
};

export type MutationDeleteAdminUserArgs = {
  input: DeleteAdminUserInput;
};

export type MutationUpdateAdminUserArgs = {
  input: UpdateAdminUserInput;
};

export enum OrderByEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Pagination = {
  __typename?: 'Pagination';
  /** Current page */
  currentPage: Scalars['Int'];
  /** Items per page */
  itemsPerPage: Scalars['Int'];
  /** Total items */
  totalItems: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

export enum Period {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
}

export type Plan = {
  __typename?: 'Plan';
  /** Plan ID */
  _id: Scalars['String'];
  /** endpoint limit count */
  endpointLimitCount: Scalars['Float'];
  /** plan name */
  plan: PlanName;
  /** request limit count */
  requestLimitCount: Scalars['Float'];
  /** request limit period */
  requestLimitPeriod: Period;
};

export enum PlanName {
  Growth = 'GROWTH',
  Large = 'LARGE',
  Small = 'SMALL',
  Trial = 'TRIAL',
}

export type Query = {
  __typename?: 'Query';
  getEndpointServiceAwsAccount: EndpointServiceAws;
  getEndpointServiceUser: EndpointUser;
  getMe?: Maybe<AdminUser>;
  listAdminUsers?: Maybe<ListAdminUsersResponse>;
  listDevProgramAccounts?: Maybe<ListDevProgramAccountsResponse>;
  listEndpointServicePlans: ListPlansResponse;
  listEndpointServiceUsers?: Maybe<ListEndpointUsersResponse>;
};

export type QueryGetEndpointServiceUserArgs = {
  input: GetEndpointServiceUserInput;
};

export type QueryListAdminUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderByEnum>;
  page?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ListAdminUsersSortByEnum>;
};

export type QueryListDevProgramAccountsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderByEnum>;
  page?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ListDevProgramAccountsSortByEnum>;
};

export type QueryListEndpointServicePlansArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderByEnum>;
  page?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ListPlansSortByEnum>;
};

export type QueryListEndpointServiceUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderByEnum>;
  page?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ListEndpointUsersSortByEnum>;
};

export type UpdateAdminUserInput = {
  /** Admin ID */
  adminId: Scalars['String'];
  /** Role */
  role?: InputMaybe<AdminUserRoleEnum>;
};

export type UserAwsDto = {
  __typename?: 'UserAwsDTO';
  /** Account ID */
  apiKeyId: Scalars['String'];
  /** API Key Value */
  apiKeyValue: Scalars['String'];
  /** AWS Account Id */
  awsAccountId: Scalars['String'];
  /** Region Name */
  regionName: AwsRegion;
};

export type CreateAdminUserMutationVariables = Exact<{
  input: CreateAdminInputDto;
}>;

export type CreateAdminUserMutation = {
  __typename?: 'Mutation';
  createAdminUser?: {
    __typename?: 'AdminUser';
    adminId?: string | null;
    ownerUid?: string | null;
    email: string;
    name?: string | null;
    role?: string | null;
    createdAt?: any | null;
  } | null;
};

export type DeleteAdminUserMutationVariables = Exact<{
  input: DeleteAdminUserInput;
}>;

export type DeleteAdminUserMutation = { __typename?: 'Mutation'; deleteAdminUser?: boolean | null };

export type UpdateAdminUserMutationVariables = Exact<{
  input: UpdateAdminUserInput;
}>;

export type UpdateAdminUserMutation = {
  __typename?: 'Mutation';
  updateAdminUser?: {
    __typename?: 'AdminUser';
    adminId?: string | null;
    ownerUid?: string | null;
    email: string;
    name?: string | null;
    role?: string | null;
    createdAt?: any | null;
  } | null;
};

export type GetEndpointServiceAwsAccountQueryVariables = Exact<{ [key: string]: never }>;

export type GetEndpointServiceAwsAccountQuery = {
  __typename?: 'Query';
  getEndpointServiceAwsAccount: {
    __typename?: 'EndpointServiceAws';
    accountId: string;
    accountName: string;
    isAvailable: boolean;
    createdAt: any;
    updatedAt?: any | null;
    regions?: Array<{
      __typename?: 'EndpointServiceRegion';
      regionName: AwsRegion;
      isAvailable: boolean;
      usagePlans?: Array<{
        __typename?: 'EndpointServiceUsagePlan';
        usagePlanId?: string | null;
        name?: PlanName | null;
        apiIds?: Array<string> | null;
        keyIds?: Array<string> | null;
      }> | null;
      apis?: Array<{
        __typename?: 'EndpointServiceApi';
        apiId?: string | null;
        name?: string | null;
        endpointType?: EndpointType | null;
        urlPath?: string | null;
        rootResourceId?: string | null;
        authorizerId?: string | null;
        isDomainConnected?: boolean | null;
      }> | null;
    }> | null;
  };
};

export type GetEndpointServiceUserQueryVariables = Exact<{
  input: GetEndpointServiceUserInput;
}>;

export type GetEndpointServiceUserQuery = {
  __typename?: 'Query';
  getEndpointServiceUser: {
    __typename?: 'EndpointUser';
    accountId: string;
    ownerUid: string;
    name?: string | null;
    plan?: string | null;
    createdAt: any;
    aws?: {
      __typename?: 'UserAwsDTO';
      awsAccountId: string;
      regionName: AwsRegion;
      apiKeyId: string;
      apiKeyValue: string;
    } | null;
    endpoints?: Array<{
      __typename?: 'Endpoint';
      endpointId: string;
      apiId: string;
      resourceId: string;
      name: string;
      status: EndpointStatus;
    }> | null;
  };
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: 'Query';
  getMe?: {
    __typename?: 'AdminUser';
    adminId?: string | null;
    ownerUid?: string | null;
    email: string;
    name?: string | null;
    role?: string | null;
    createdAt?: any | null;
  } | null;
};

export type ListAdminUsersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ListAdminUsersSortByEnum>;
  orderBy?: InputMaybe<OrderByEnum>;
}>;

export type ListAdminUsersQuery = {
  __typename?: 'Query';
  listAdminUsers?: {
    __typename?: 'ListAdminUsersResponse';
    items: Array<{
      __typename?: 'AdminUser';
      adminId?: string | null;
      ownerUid?: string | null;
      email: string;
      name?: string | null;
      role?: string | null;
      createdAt?: any | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      totalItems: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    sorting: {
      __typename?: 'ListAdminUsersSortingOptionsResponse';
      sortBy?: ListAdminUsersSortByEnum | null;
      orderBy?: OrderByEnum | null;
    };
  } | null;
};

export type ListDevProgramAccountsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ListDevProgramAccountsSortByEnum>;
  orderBy?: InputMaybe<OrderByEnum>;
}>;

export type ListDevProgramAccountsQuery = {
  __typename?: 'Query';
  listDevProgramAccounts?: {
    __typename?: 'ListDevProgramAccountsResponse';
    items: Array<{
      __typename?: 'DevProgramAccount';
      accountId: string;
      ownerUid: string;
      name?: string | null;
      isAcceptTermOfService: boolean;
      status: DevProgramMemberStatus;
      createdAt: any;
    }>;
    pagination: {
      __typename?: 'Pagination';
      totalItems: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    sorting: {
      __typename?: 'ListDevProgramAccountSortResponse';
      orderBy?: OrderByEnum | null;
      sortBy?: ListDevProgramAccountsSortByEnum | null;
    };
  } | null;
};

export type ListEndpointServicePlansQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ListPlansSortByEnum>;
  orderBy?: InputMaybe<OrderByEnum>;
}>;

export type ListEndpointServicePlansQuery = {
  __typename?: 'Query';
  listEndpointServicePlans: {
    __typename?: 'ListPlansResponse';
    items: Array<{
      __typename?: 'Plan';
      _id: string;
      plan: PlanName;
      requestLimitCount: number;
      requestLimitPeriod: Period;
      endpointLimitCount: number;
    }>;
    pagination: {
      __typename?: 'Pagination';
      totalItems: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    sorting: {
      __typename?: 'ListPlansSortResponse';
      orderBy?: OrderByEnum | null;
      sortBy?: ListPlansSortByEnum | null;
    };
  };
};

export type ListEndpointServiceUsersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ListEndpointUsersSortByEnum>;
  orderBy?: InputMaybe<OrderByEnum>;
}>;

export type ListEndpointServiceUsersQuery = {
  __typename?: 'Query';
  listEndpointServiceUsers?: {
    __typename?: 'ListEndpointUsersResponse';
    items: Array<{
      __typename?: 'EndpointUser';
      accountId: string;
      ownerUid: string;
      name?: string | null;
      plan?: string | null;
      createdAt: any;
    }>;
    pagination: {
      __typename?: 'Pagination';
      totalItems: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    sorting: {
      __typename?: 'ListEndpointUsersSortResponse';
      orderBy?: OrderByEnum | null;
      sortBy?: ListEndpointUsersSortByEnum | null;
    };
  } | null;
};

export const CreateAdminUserDocument = gql`
  mutation CreateAdminUser($input: CreateAdminInputDTO!) {
    createAdminUser(input: $input) {
      adminId
      ownerUid
      email
      name
      role
      createdAt
    }
  }
`;
export type CreateAdminUserMutationFn = Apollo.MutationFunction<
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables
>;

/**
 * __useCreateAdminUserMutation__
 *
 * To run a mutation, you first call `useCreateAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminUserMutation, { data, loading, error }] = useCreateAdminUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAdminUserMutation,
    CreateAdminUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAdminUserMutation, CreateAdminUserMutationVariables>(
    CreateAdminUserDocument,
    options,
  );
}
export type CreateAdminUserMutationHookResult = ReturnType<typeof useCreateAdminUserMutation>;
export type CreateAdminUserMutationResult = Apollo.MutationResult<CreateAdminUserMutation>;
export type CreateAdminUserMutationOptions = Apollo.BaseMutationOptions<
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables
>;
export const DeleteAdminUserDocument = gql`
  mutation DeleteAdminUser($input: DeleteAdminUserInput!) {
    deleteAdminUser(input: $input)
  }
`;
export type DeleteAdminUserMutationFn = Apollo.MutationFunction<
  DeleteAdminUserMutation,
  DeleteAdminUserMutationVariables
>;

/**
 * __useDeleteAdminUserMutation__
 *
 * To run a mutation, you first call `useDeleteAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminUserMutation, { data, loading, error }] = useDeleteAdminUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAdminUserMutation,
    DeleteAdminUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>(
    DeleteAdminUserDocument,
    options,
  );
}
export type DeleteAdminUserMutationHookResult = ReturnType<typeof useDeleteAdminUserMutation>;
export type DeleteAdminUserMutationResult = Apollo.MutationResult<DeleteAdminUserMutation>;
export type DeleteAdminUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteAdminUserMutation,
  DeleteAdminUserMutationVariables
>;
export const UpdateAdminUserDocument = gql`
  mutation UpdateAdminUser($input: UpdateAdminUserInput!) {
    updateAdminUser(input: $input) {
      adminId
      ownerUid
      email
      name
      role
      createdAt
    }
  }
`;
export type UpdateAdminUserMutationFn = Apollo.MutationFunction<
  UpdateAdminUserMutation,
  UpdateAdminUserMutationVariables
>;

/**
 * __useUpdateAdminUserMutation__
 *
 * To run a mutation, you first call `useUpdateAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminUserMutation, { data, loading, error }] = useUpdateAdminUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdminUserMutation,
    UpdateAdminUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAdminUserMutation, UpdateAdminUserMutationVariables>(
    UpdateAdminUserDocument,
    options,
  );
}
export type UpdateAdminUserMutationHookResult = ReturnType<typeof useUpdateAdminUserMutation>;
export type UpdateAdminUserMutationResult = Apollo.MutationResult<UpdateAdminUserMutation>;
export type UpdateAdminUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdminUserMutation,
  UpdateAdminUserMutationVariables
>;
export const GetEndpointServiceAwsAccountDocument = gql`
  query GetEndpointServiceAwsAccount {
    getEndpointServiceAwsAccount {
      accountId
      accountName
      isAvailable
      createdAt
      updatedAt
      regions {
        regionName
        isAvailable
        usagePlans {
          usagePlanId
          name
          apiIds
          keyIds
        }
        apis {
          apiId
          name
          endpointType
          urlPath
          rootResourceId
          authorizerId
          isDomainConnected
        }
      }
    }
  }
`;

/**
 * __useGetEndpointServiceAwsAccountQuery__
 *
 * To run a query within a React component, call `useGetEndpointServiceAwsAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndpointServiceAwsAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndpointServiceAwsAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEndpointServiceAwsAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEndpointServiceAwsAccountQuery,
    GetEndpointServiceAwsAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEndpointServiceAwsAccountQuery,
    GetEndpointServiceAwsAccountQueryVariables
  >(GetEndpointServiceAwsAccountDocument, options);
}
export function useGetEndpointServiceAwsAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEndpointServiceAwsAccountQuery,
    GetEndpointServiceAwsAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEndpointServiceAwsAccountQuery,
    GetEndpointServiceAwsAccountQueryVariables
  >(GetEndpointServiceAwsAccountDocument, options);
}
export type GetEndpointServiceAwsAccountQueryHookResult = ReturnType<
  typeof useGetEndpointServiceAwsAccountQuery
>;
export type GetEndpointServiceAwsAccountLazyQueryHookResult = ReturnType<
  typeof useGetEndpointServiceAwsAccountLazyQuery
>;
export type GetEndpointServiceAwsAccountQueryResult = Apollo.QueryResult<
  GetEndpointServiceAwsAccountQuery,
  GetEndpointServiceAwsAccountQueryVariables
>;
export const GetEndpointServiceUserDocument = gql`
  query GetEndpointServiceUser($input: GetEndpointServiceUserInput!) {
    getEndpointServiceUser(input: $input) {
      accountId
      ownerUid
      name
      plan
      createdAt
      aws {
        awsAccountId
        regionName
        apiKeyId
        apiKeyValue
      }
      endpoints {
        endpointId
        apiId
        resourceId
        name
        status
      }
    }
  }
`;

/**
 * __useGetEndpointServiceUserQuery__
 *
 * To run a query within a React component, call `useGetEndpointServiceUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndpointServiceUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndpointServiceUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEndpointServiceUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEndpointServiceUserQuery,
    GetEndpointServiceUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEndpointServiceUserQuery, GetEndpointServiceUserQueryVariables>(
    GetEndpointServiceUserDocument,
    options,
  );
}
export function useGetEndpointServiceUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEndpointServiceUserQuery,
    GetEndpointServiceUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEndpointServiceUserQuery, GetEndpointServiceUserQueryVariables>(
    GetEndpointServiceUserDocument,
    options,
  );
}
export type GetEndpointServiceUserQueryHookResult = ReturnType<
  typeof useGetEndpointServiceUserQuery
>;
export type GetEndpointServiceUserLazyQueryHookResult = ReturnType<
  typeof useGetEndpointServiceUserLazyQuery
>;
export type GetEndpointServiceUserQueryResult = Apollo.QueryResult<
  GetEndpointServiceUserQuery,
  GetEndpointServiceUserQueryVariables
>;
export const GetMeDocument = gql`
  query GetMe {
    getMe {
      adminId
      ownerUid
      email
      name
      role
      createdAt
    }
  }
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const ListAdminUsersDocument = gql`
  query ListAdminUsers(
    $page: Int
    $limit: Int
    $sortBy: ListAdminUsersSortByEnum
    $orderBy: OrderByEnum
  ) {
    listAdminUsers(page: $page, limit: $limit, sortBy: $sortBy, orderBy: $orderBy) {
      items {
        adminId
        ownerUid
        email
        name
        role
        createdAt
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
      sorting {
        sortBy
        orderBy
      }
    }
  }
`;

/**
 * __useListAdminUsersQuery__
 *
 * To run a query within a React component, call `useListAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListAdminUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<ListAdminUsersQuery, ListAdminUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListAdminUsersQuery, ListAdminUsersQueryVariables>(
    ListAdminUsersDocument,
    options,
  );
}
export function useListAdminUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListAdminUsersQuery, ListAdminUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListAdminUsersQuery, ListAdminUsersQueryVariables>(
    ListAdminUsersDocument,
    options,
  );
}
export type ListAdminUsersQueryHookResult = ReturnType<typeof useListAdminUsersQuery>;
export type ListAdminUsersLazyQueryHookResult = ReturnType<typeof useListAdminUsersLazyQuery>;
export type ListAdminUsersQueryResult = Apollo.QueryResult<
  ListAdminUsersQuery,
  ListAdminUsersQueryVariables
>;
export const ListDevProgramAccountsDocument = gql`
  query ListDevProgramAccounts(
    $page: Int
    $limit: Int
    $sortBy: ListDevProgramAccountsSortByEnum
    $orderBy: OrderByEnum
  ) {
    listDevProgramAccounts(page: $page, limit: $limit, sortBy: $sortBy, orderBy: $orderBy) {
      items {
        accountId
        ownerUid
        name
        isAcceptTermOfService
        status
        createdAt
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
      sorting {
        orderBy
        sortBy
      }
    }
  }
`;

/**
 * __useListDevProgramAccountsQuery__
 *
 * To run a query within a React component, call `useListDevProgramAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDevProgramAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDevProgramAccountsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListDevProgramAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListDevProgramAccountsQuery,
    ListDevProgramAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListDevProgramAccountsQuery, ListDevProgramAccountsQueryVariables>(
    ListDevProgramAccountsDocument,
    options,
  );
}
export function useListDevProgramAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListDevProgramAccountsQuery,
    ListDevProgramAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListDevProgramAccountsQuery, ListDevProgramAccountsQueryVariables>(
    ListDevProgramAccountsDocument,
    options,
  );
}
export type ListDevProgramAccountsQueryHookResult = ReturnType<
  typeof useListDevProgramAccountsQuery
>;
export type ListDevProgramAccountsLazyQueryHookResult = ReturnType<
  typeof useListDevProgramAccountsLazyQuery
>;
export type ListDevProgramAccountsQueryResult = Apollo.QueryResult<
  ListDevProgramAccountsQuery,
  ListDevProgramAccountsQueryVariables
>;
export const ListEndpointServicePlansDocument = gql`
  query ListEndpointServicePlans(
    $page: Int
    $limit: Int
    $sortBy: ListPlansSortByEnum
    $orderBy: OrderByEnum
  ) {
    listEndpointServicePlans(page: $page, limit: $limit, sortBy: $sortBy, orderBy: $orderBy) {
      items {
        _id
        plan
        requestLimitCount
        requestLimitPeriod
        endpointLimitCount
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
      sorting {
        orderBy
        sortBy
      }
    }
  }
`;

/**
 * __useListEndpointServicePlansQuery__
 *
 * To run a query within a React component, call `useListEndpointServicePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEndpointServicePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEndpointServicePlansQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListEndpointServicePlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListEndpointServicePlansQuery,
    ListEndpointServicePlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListEndpointServicePlansQuery, ListEndpointServicePlansQueryVariables>(
    ListEndpointServicePlansDocument,
    options,
  );
}
export function useListEndpointServicePlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListEndpointServicePlansQuery,
    ListEndpointServicePlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListEndpointServicePlansQuery, ListEndpointServicePlansQueryVariables>(
    ListEndpointServicePlansDocument,
    options,
  );
}
export type ListEndpointServicePlansQueryHookResult = ReturnType<
  typeof useListEndpointServicePlansQuery
>;
export type ListEndpointServicePlansLazyQueryHookResult = ReturnType<
  typeof useListEndpointServicePlansLazyQuery
>;
export type ListEndpointServicePlansQueryResult = Apollo.QueryResult<
  ListEndpointServicePlansQuery,
  ListEndpointServicePlansQueryVariables
>;
export const ListEndpointServiceUsersDocument = gql`
  query ListEndpointServiceUsers(
    $page: Int
    $limit: Int
    $sortBy: ListEndpointUsersSortByEnum
    $orderBy: OrderByEnum
  ) {
    listEndpointServiceUsers(page: $page, limit: $limit, sortBy: $sortBy, orderBy: $orderBy) {
      items {
        accountId
        ownerUid
        name
        plan
        createdAt
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
      sorting {
        orderBy
        sortBy
      }
    }
  }
`;

/**
 * __useListEndpointServiceUsersQuery__
 *
 * To run a query within a React component, call `useListEndpointServiceUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEndpointServiceUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEndpointServiceUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListEndpointServiceUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListEndpointServiceUsersQuery,
    ListEndpointServiceUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListEndpointServiceUsersQuery, ListEndpointServiceUsersQueryVariables>(
    ListEndpointServiceUsersDocument,
    options,
  );
}
export function useListEndpointServiceUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListEndpointServiceUsersQuery,
    ListEndpointServiceUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListEndpointServiceUsersQuery, ListEndpointServiceUsersQueryVariables>(
    ListEndpointServiceUsersDocument,
    options,
  );
}
export type ListEndpointServiceUsersQueryHookResult = ReturnType<
  typeof useListEndpointServiceUsersQuery
>;
export type ListEndpointServiceUsersLazyQueryHookResult = ReturnType<
  typeof useListEndpointServiceUsersLazyQuery
>;
export type ListEndpointServiceUsersQueryResult = Apollo.QueryResult<
  ListEndpointServiceUsersQuery,
  ListEndpointServiceUsersQueryVariables
>;
