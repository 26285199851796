import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: '24px',
    textTransform: 'uppercase',
    marginBottom: '30px',
  },
}));

export { useStyles };
