export enum ENDPOINT_ACCOUNT_TABS {
  INFO = 'INFO',
  ENDPOINTS = 'ENDPOINTS',
  AWS = 'AWS',
}

export enum RoleEnums {
  ADMIN = 'administrator',
  OWNER = 'owner',
}
