import React, { useCallback, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import DeleteAdminUserDialog from '../DeleteAdminUserDialog';
import UpdateAdminUserRoleDialog from '../UpdateAdminUser';
import { AdminUser } from '~/graphql/admin-api/types';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  isNotOwner: boolean;
  isOwner: boolean;
  id: string;
  adminUser: AdminUser;
  onRefetchData: () => Promise<any>;
  refetchAdminUserData: () => Promise<any>;
}

const MoreActionButton: React.FC<Props> = (props) => {
  const { adminUser, isNotOwner, isOwner, onRefetchData, refetchAdminUserData } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();
  const [openConfimDialog, setOpenConfirmDialog] = useState(false);
  const [openConfimDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onOpenPopover = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
      event.preventDefault();
    },
    [setAnchorEl],
  );
  const onClosePopover = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onCloseConfirmDialog = useCallback(() => {
    setOpenConfirmDialog(false);
  }, []);

  const onCloseDeleteDialog = useCallback(() => {
    setOpenConfirmDeleteDialog(false);
  }, []);

  const onOpenConfirmChangeRoleDialog = useCallback((id: string) => {
    setAnchorEl(null);
    setOpenConfirmDialog(true);
  }, []);

  const onOpenDeleteDialog = useCallback((id: string) => {
    setAnchorEl(null);
    setOpenConfirmDeleteDialog(true);
  }, []);

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List className={classes.list} disablePadding>
          <ListItem
            className={classes.listItem}
            onClick={() => onOpenConfirmChangeRoleDialog(adminUser.adminId as string)}
          >
            <Typography>{t('edit')}</Typography>
          </ListItem>
          <ListItem
            className={classes.listItem}
            onClick={() => onOpenDeleteDialog(adminUser.adminId as string)}
          >
            <Typography>{t('delete')}</Typography>
          </ListItem>
        </List>
      </Popover>
      <IconButton disabled={isNotOwner || isOwner} onClick={onOpenPopover}>
        <MoreVertIcon />
      </IconButton>
      <UpdateAdminUserRoleDialog
        onRefetchData={onRefetchData}
        refechUserData={refetchAdminUserData}
        adminUser={adminUser}
        open={openConfimDialog}
        onClose={onCloseConfirmDialog}
      />
      <DeleteAdminUserDialog
        refech={onRefetchData}
        adminUser={adminUser}
        open={openConfimDeleteDialog}
        onClose={onCloseDeleteDialog}
      />
    </>
  );
};

export default MoreActionButton;
