import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import firebase from 'firebase/app';

export const useLogout = () => {
  const client = useApolloClient();
  const logout = useCallback(() => {
    firebase.auth().signOut();
    client.clearStore();
  }, [client]);

  return logout;
};
