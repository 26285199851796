import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  open: boolean;
  onClose: () => void;
  data: string[] | null | undefined;
  headerName: string;
}

const InformationDialog: React.FC<Props> = (props) => {
  const { open, data, headerName, onClose } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      className={classes.dialog}
      open={open}
      data-testid="delete-admin-user-dialog"
    >
      <IconButton onClick={onClose} className={classes.closeIcon} data-testid="close-button">
        <img src="/images/close_icon.svg" alt="" />
      </IconButton>
      <DialogTitle>
        <Typography variant="h5" color="primary" component="p">
          {headerName}
        </Typography>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent>
        {data && (
          <List>
            {data.map((item, index) => (
              <ListItem key={index}>
                <Typography sx={{ display: 'flex' }}>
                  {index + 1} <Typography className={classes.item}>{item}</Typography>
                </Typography>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InformationDialog;
