import Box from '@mui/material/Box';
import {
  DataGridPro,
  DataGridProProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useStyles } from './style';
interface DataGridProComponent {
  <R extends GridValidRowModel = any>(
    props: DataGridProProps<R> & React.RefAttributes<HTMLDivElement> & { tableName?: string },
  ): JSX.Element;
  propTypes?: any;
}

const CustomDataGrid: DataGridProComponent = ({ className, ...props }) => {
  const { rows } = props;
  const { t } = useTranslation();

  const { classes, cx } = useStyles({ emptyRows: !rows.length });
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  };

  return (
    <Box className={classes.wrapperListTable}>
      <DataGridPro
        autoHeight
        slots={{
          toolbar: CustomToolbar,
        }}
        disableMultipleColumnsFiltering
        localeText={{
          toolbarColumns: t('data_grid.columns'),
          toolbarFilters: t('data_grid.filters'),
          toolbarExport: t('data_grid.export'),
          filterPanelColumns: t('data_grid.columns'),
          columnsPanelTextFieldLabel: t('data_grid.find_column'),
          columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
          columnsPanelDragIconLabel: t('data_grid.reorder_column'),
          columnsPanelShowAllButton: t('data_grid.show_all'),
          columnsPanelHideAllButton: t('data_grid.hide_all'),
          noRowsLabel: t('data_grid.no_data'),
          noResultsOverlayLabel: t('data_grid.no_data'),
        }}
        slotProps={{ filterPanel: { sx: { maxWidth: '100vw' } } }}
        {...props}
        className={cx(classes.dataGrid, className)}
      />
    </Box>
  );
};

export default CustomDataGrid;
