import { useMemo } from 'react';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { useTranslation } from 'react-i18next';
import { apolloLinkConfig } from '~/configs/apollo.link.config';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';

const cache = new InMemoryCache();
export const withApolloClient = (ChildComponent: any) => {
  const WithApolloClientHOC = (props: any) => {
    const user = useFirebaseUser();
    const { i18n } = useTranslation();
    const client = useMemo(() => {
      const adminUserLink = createHttpLink({
        uri: apolloLinkConfig.adminUser,
      });

      const asyncAuthLink = setContext(async (request, prevContext) => {
        const token = user && (await user.getIdToken());
        return {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
            'Accept-Language': i18n.language,
          },
        };
      });

      const errorLink = onError(({ graphQLErrors }) => {
        graphQLErrors?.forEach((err) => {
          const messageI18n = (err.extensions?.localizedMessages as Record<string, string>)?.[
            i18n.language
          ];
          if (messageI18n) {
            err.message = messageI18n;
          }
        });
      });

      return new ApolloClient({
        link: asyncAuthLink.concat(from([errorLink, adminUserLink])),
        cache,
      });
    }, [user, i18n.language]);

    return (
      <ApolloProvider client={client}>
        <ChildComponent {...props} />
      </ApolloProvider>
    );
  };
  WithApolloClientHOC.displayName = 'WithApolloClientHOC';
  hoistNonReactStatics(WithApolloClientHOC, ChildComponent);

  return WithApolloClientHOC;
};
