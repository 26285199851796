import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  popoverPaper: {
    marginTop: 8,
    borderRadius: 4,
    width: 250,
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 10px',
    width: '100%',
    overflow: 'hidden',
  },
  avatarImg: {
    width: 42,
    height: 42,
    borderRadius: '50%',
    marginRight: 10,
  },
  displayNameText: {
    lineHeight: '18px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100% - 10px)',
  },
  list: {
    padding: '0px 8px 8px 8px',
  },
  listItem: {
    marginTop: 8,
    marginBottom: 8,
    borderRadius: 4,
  },
  email: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100% - 10px)',
  },
}));

export { useStyles };
