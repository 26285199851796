import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const LoaderCenter: React.FC<Props> = (props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};

export default LoaderCenter;
