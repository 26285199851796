import React, { memo, useCallback, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, useNavigate } from 'react-router-dom';

import NavigatorItemChildren from '../NavigatorItemChild';
import { AppRoutesEnum } from '~/enums/route.enum';
import { StyledComponentProps } from '~/types/material-ui';

import { useStyles } from './style';

export type NavigatorItemConfig = {
  path?: string;
  label: string;
  icon?: JSX.Element;
  children?: NavigatorItemConfig[];
};

interface Props extends StyledComponentProps<typeof useStyles> {
  item: NavigatorItemConfig;
}

const NavigatorItem: React.FC<Props> = (props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { item } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const childItem = localStorage.getItem('childNavigator') || '';
  const initOpen = item.children
    ? (item.children.map((item) => item.path).includes(childItem) as boolean)
    : false;

  const [open, setOpen] = useState(initOpen);
  const handleClick = useCallback(() => {
    if (item.path) {
      navigate(item.path);
      localStorage.removeItem('childNavigator');
    } else if (item.children) {
      setOpen((o) => !o);
    }
  }, [item, navigate]);

  const isSelected =
    item.path?.length === 1
      ? location.pathname === AppRoutesEnum.HOME
      : new RegExp(`^${item.path}`, 'g').test(location.pathname);

  return (
    <>
      <ListItemButton
        selected={isSelected}
        className={`${classes.listItem} ${isSelected ? classes.selectedItem : ''}`}
        onClick={handleClick}
      >
        {!!item.icon && (
          <ListItemIcon className={`${classes.listItemIcon} ${isSelected}`}>
            {item.icon}
          </ListItemIcon>
        )}
        <ListItemText className={classes.listItemLabel} primary={item.label} />
        {!!item.children && (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </ListItemButton>
      {!!item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List className={classes.childItem}>
            {item.children.map((i, index) => (
              <NavigatorItemChildren item={i} key={index} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default memo(NavigatorItem);
