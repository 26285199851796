import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';
import FirebaseAuthLocalized from './firebase-auth-localized';
import AuthLayout from '~/components/AuthLayout';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const AuthPage = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const firebaseUser = useFirebaseUser();
  const handleLogout = useCallback(() => {
    firebase.auth().signOut();
  }, []);

  return (
    <AuthLayout>
      <Paper className={classes.paper}>
        <div>
          {!firebaseUser && (
            <Typography variant="overline" className={classes.title}>
              {t('sign_in')}
            </Typography>
          )}
        </div>
        <div className={classes.logo}>
          <img src="/images/logo.svg" alt="" />
        </div>
        <div>
          {firebaseUser && !firebaseUser.emailVerified ? (
            <>
              <Typography variant="body1" className={classes.emailConfirmationWaring}>
                {t('email_confirmation_warning')}
              </Typography>
              <Button
                className={classes.btnLogout}
                size="small"
                variant="contained"
                onClick={handleLogout}
              >
                {t('logout')}
              </Button>
            </>
          ) : (
            <FirebaseAuthLocalized language={i18n.language} />
          )}
        </div>
      </Paper>
    </AuthLayout>
  );
};

export default AuthPage;
