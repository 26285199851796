import { makeStyles } from 'tss-react/mui';

import { DRAWER_WIDTH, DRAWER_WIDTH_SM } from '~/constants/layout';

const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: DRAWER_WIDTH_SM,
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
    },
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    padding: theme.spacing(2),
    width: DRAWER_WIDTH_SM,
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
    },
  },
  logo: {
    '& img': {
      width: 130,
      [theme.breakpoints.up('sm')]: {
        width: 170,
      },
    },
  },
}));

export { useStyles };
