type EnvType = {
  REACT_APP_FIREBASE_PUBLIC_API_KEY: string;
  REACT_APP_FIREBASE_AUTH_DOMAIN: string;

  REACT_APP_FIREBASE_DATABASE_URL: string;
  REACT_APP_FIREBASE_PROJECT_ID: string;
  REACT_APP_FIREBASE_STORAGE_BUCKET: string;
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string;
  REACT_APP_FIREBASE_APP_ID: string;

  REACT_APP_ADMIN_API: string;
};

export const getEnv = (name: keyof EnvType) => {
  return {
    ...(typeof window !== 'undefined' && (window as any)['env'] ? (window as any)['env'] : {}),
    ...process.env,
  }[name];
};
