import { useState, useCallback, useMemo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import InformationDialog from './DetailDialog';
import UserLayout from '~/components/AppLayout';
import LoaderCener from '~/components/LoaderCenter';
import {
  AwsRegion,
  EndpointType,
  PlanName,
  useGetEndpointServiceAwsAccountQuery,
} from '~/graphql/admin-api/types';
import { withApolloClient } from '~/graphql/client';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

type ApiRow = {
  apiId: string | null | undefined;
  name: string | null | undefined;
  authorizerId: string | null | undefined;
  endpointType: EndpointType | null | undefined;
  isDomainConnected: boolean | null | undefined;
  rootResourceId: string | null | undefined;
  urlPath: string | null | undefined;
};

type UsageRow = {
  id: string;
  region: AwsRegion;
  usagePlanId: string | null | undefined;
  name: PlanName | null | undefined;
  apiIds: string[] | null | undefined;
  keyIds: string[] | null | undefined;
};

interface Props extends StyledComponentProps<typeof useStyles> {}

const AwsPage = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const [open, setOpen] = useState(false);
  const [dialogHeaderName, setDialogHeaderName] = useState('');
  const [apiData, setApiData] = useState([]);
  const { t } = useTranslation();
  const {
    data: accountAwsDetail,
    loading: loadingAccountAwsDetail,
    refetch: refetchAccountAws,
  } = useGetEndpointServiceAwsAccountQuery({
    fetchPolicy: 'cache-and-network',
  });

  const checkAvailableStatus = () => {
    return accountAwsDetail?.getEndpointServiceAwsAccount.isAvailable
      ? t('aws_page.available')
      : t('aws_page.unavailable');
  };

  const handleOpenInformationDialog = useCallback((data, name) => {
    setDialogHeaderName(name);
    setApiData(data);
    setOpen(true);
  }, []);

  const handleCloseInformationDialog = useCallback(() => {
    setOpen(false);
  }, []);

  const apiRows = useMemo(() => {
    return (accountAwsDetail?.getEndpointServiceAwsAccount.regions || []).flatMap(
      (region, regionIndex) => {
        return (region.apis || []).map((api, index) => ({
          id: `${regionIndex + 1}.${index + 1}`,
          region: region.regionName,
          apiId: api.apiId,
          name: api.name,
          authorizerId: api.authorizerId,
          endpointType: api.endpointType,
          isDomainConnected: api.isDomainConnected,
          rootResourceId: api.rootResourceId,
          urlPath: api.urlPath,
        }));
      },
    );
  }, [accountAwsDetail?.getEndpointServiceAwsAccount.regions]);

  const columnsApi = useMemo<GridColDef<ApiRow>[]>(
    () => [
      {
        field: 'name',
        headerName: t('aws_page.api_name'),
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => <Typography>{row.name || '-'}</Typography>,
      },
      {
        field: 'urlPath',
        headerName: t('aws_page.url_path'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <Typography>{row.urlPath || '-'}</Typography>,
      },
      {
        field: 'isDomainConnected',
        headerName: t('aws_page.domain_connected'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography>
            {row.isDomainConnected ? t('aws_page.connected') : t('aws_page.not_connected')}
          </Typography>
        ),
      },
      {
        field: 'authorizerId',
        headerName: t('aws_page.authorized_id'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <Typography>{row.authorizerId || '-'}</Typography>,
      },
      {
        field: 'endpointType',
        headerName: t('aws_page.endpoint_type'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <Typography>{row.endpointType || '-'}</Typography>,
      },
      {
        field: 'rootResourceId',
        headerName: t('aws_page.root_resource_id'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <Typography>{row.rootResourceId || '-'}</Typography>,
      },
    ],
    [t],
  );

  const usagePlanRows = useMemo(() => {
    return (accountAwsDetail?.getEndpointServiceAwsAccount.regions || []).flatMap(
      (region, regionIndex) => {
        return (region.usagePlans || []).map((usagePlan, index) => ({
          id: `${regionIndex + 1}.${index + 1}`,
          region: region.regionName,
          usagePlanId: usagePlan.usagePlanId,
          name: usagePlan.name,
          apiIds: usagePlan.apiIds,
          keyIds: usagePlan.keyIds,
        }));
      },
    );
  }, [accountAwsDetail?.getEndpointServiceAwsAccount.regions]);

  const columnsUsagePlan = useMemo<GridColDef<UsageRow>[]>(
    () => [
      {
        field: 'usagePlanId',
        headerName: t('aws_page.usage_plan_id'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <Typography>{row.usagePlanId || '-'}</Typography>,
      },
      {
        field: 'name',
        headerName: t('aws_page.usage_plan_type'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography>{t(`endpoint_plan_name_enum.${row.name?.toLowerCase()}`) || '-'}</Typography>
        ),
      },
      {
        field: 'apiIds',
        headerName: t('aws_page.api_id'),
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <Typography
            className={classes.viewAll}
            onClick={() => handleOpenInformationDialog(row.apiIds, 'API IDs')}
          >
            {t('view_all')}
          </Typography>
        ),
      },
      {
        field: 'keyIds',
        headerName: t('aws_page.key_id'),
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <Typography
            className={classes.viewAll}
            onClick={() => handleOpenInformationDialog(row.keyIds, 'Key IDs')}
          >
            {t('view_all')}
          </Typography>
        ),
      },
    ],
    [t],
  );

  return (
    <UserLayout>
      <div style={{ width: '100%' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Typography>{t('endpoint_service.endpoint_service')}</Typography>
          <Typography>{t('aws_page.aws_page_title')}</Typography>
        </Breadcrumbs>
        <Typography className={classes.pageTitle}>{t('aws_page.aws_page_title')}</Typography>
        {loadingAccountAwsDetail ? (
          <LoaderCener />
        ) : (
          <div className={classes.root}>
            <Box className={classes.wrapper}>
              <Typography className={classes.title}>{t('account_id')}</Typography>
              <Typography className={classes.infomation}>
                {accountAwsDetail?.getEndpointServiceAwsAccount.accountId || '-'}
              </Typography>
            </Box>
            <Box className={classes.wrapper}>
              <Typography className={classes.title}>{t('account_name')}</Typography>
              <Typography className={classes.infomation}>
                {accountAwsDetail?.getEndpointServiceAwsAccount.accountName || '-'}
              </Typography>
            </Box>
            <Box className={classes.wrapper}>
              <Typography className={classes.title}>{t('aws_page.available')}</Typography>
              <Typography className={classes.infomation}>
                {checkAvailableStatus() || '-'}
              </Typography>
            </Box>
            <Box className={classes.wrapper}>
              <Typography className={classes.title}>{t('created_date')}</Typography>
              <Typography className={classes.infomation}>
                {accountAwsDetail?.getEndpointServiceAwsAccount.createdAt
                  ? moment(accountAwsDetail?.getEndpointServiceAwsAccount.createdAt).format(
                      t('date_format'),
                    )
                  : '-'}
              </Typography>
            </Box>
            <Box className={classes.wrapper}>
              <Typography className={classes.title}>{t('update_date')}</Typography>
              <Typography className={classes.infomation}>
                {accountAwsDetail?.getEndpointServiceAwsAccount.updatedAt
                  ? moment(accountAwsDetail?.getEndpointServiceAwsAccount.updatedAt).format(
                      t('date_format'),
                    )
                  : '-'}
              </Typography>
            </Box>
            <Divider variant="fullWidth" />
            <Box>
              <div>
                {accountAwsDetail?.getEndpointServiceAwsAccount.regions &&
                  accountAwsDetail?.getEndpointServiceAwsAccount.regions.map((region) => (
                    <Box className={classes.region} key={region.regionName}>
                      <Accordion>
                        <AccordionSummary
                          className={classes.collapse}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Box className={classes.collapseTitle}>
                            <Typography className={classes.regionName}>
                              {t('aws_page.region')}
                            </Typography>
                            <Typography className={classes.regionTitle}>
                              {region.regionName}
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box>
                            <Typography className={classes.tableHeader}>API</Typography>
                            <DataGridPro
                              rows={apiRows.filter((row) => row.region === region.regionName)}
                              columns={columnsApi}
                              disableRowSelectionOnClick
                              disableVirtualization
                              disableColumnMenu
                            />
                            <Typography className={classes.tableHeader}>
                              {t('aws_page.usage_plan')}
                            </Typography>
                            <DataGridPro
                              rows={usagePlanRows.filter((row) => row.region === region.regionName)}
                              columns={columnsUsagePlan}
                              disableRowSelectionOnClick
                              disableVirtualization
                              disableColumnMenu
                            />
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  ))}
              </div>
            </Box>
          </div>
        )}
        <InformationDialog
          headerName={dialogHeaderName}
          data={apiData}
          open={open}
          onClose={handleCloseInformationDialog}
        />
      </div>
    </UserLayout>
  );
};

export default compose(withApolloClient)(AwsPage);
