import { makeStyles } from 'tss-react/mui';
import {
  CONTENT_MAX_WIDTH,
  DRAWER_WIDTH,
  DRAWER_WIDTH_SM,
  HEADER_HEIGHT,
} from '~/constants/layout';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.colors.white,
  },
  rightSide: {
    position: 'relative',
    marginLeft: DRAWER_WIDTH,
    backgroundColor: theme.colors.lightGray,
    transition: 'all 250ms ease 0s',
    [theme.breakpoints.down('md')]: {
      marginLeft: DRAWER_WIDTH_SM,
    },
  },
  rightSideTemporarySideBar: {
    marginLeft: 0,
  },
  contentWrapper: {
    [theme.breakpoints.up('lg')]: {
      padding: '0 106px',
    },
    margin: 'auto',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '24px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '24px',
    },
  },
}));

export { useStyles };
