import React, { createContext, useContext } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import Error from '~/components/ErrorPage';
import ScreenLoading from '~/components/ScreenLoading';
import UnauthenticatedError from '~/components/UnauthenticatedError';
import { GetMeQuery, useGetMeQuery } from '~/graphql/admin-api/types';

const MeContext = createContext<GetMeQuery['getMe']>({} as any);

export const useMe = () => useContext(MeContext);

export const withMe = <
  P extends JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<React.Component<P, S, any>> &
    Readonly<P>,
  S,
>(
  ChildComponent: React.ComponentClass<P, S>,
) => {
  const WithMeHOC = (props: P) => {
    const { data: getMeData, loading: getMeLoading, error: getMeError } = useGetMeQuery();

    if (getMeError) {
      if (getMeError?.graphQLErrors[0]?.extensions?.code === 'UNAUTHENTICATED') {
        return <UnauthenticatedError />;
      }

      return <Error code={500} message={getMeError?.message} />;
    }

    if (getMeLoading || !getMeData) {
      return <ScreenLoading />;
    }

    return (
      <MeContext.Provider value={getMeData.getMe}>
        <ChildComponent {...props} />
      </MeContext.Provider>
    );
  };

  WithMeHOC.displayName = 'WithMeHOC';
  hoistNonReactStatics(WithMeHOC, ChildComponent);

  return WithMeHOC;
};
