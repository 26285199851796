import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  listItem: {
    borderRadius: 4,
    height: 48,
    alignItems: 'center',
  },
  listItemIcon: {
    minWidth: 40,
    '& img': {
      width: 23,
      height: 23,
    },
  },
  divider: {
    margin: 1,
  },
  selectedItem: {
    backgroundColor: '#134a8414!important',
  },
  listItemLabel: {
    '.MuiTypography-root': {
      color: '#333333',
    },
    lineHeight: '24px',
  },
  childItem: {
    marginLeft: '26px',
  },
}));

export { useStyles };
