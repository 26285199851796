import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    marginTop: '24px',
    backgroundColor: theme.colors.white,
    borderRadius: '8px',
    border: '1px solid #D7D7D7',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    padding: '12px 16px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    minWidth: 210,
    [theme.breakpoints.down('md')]: {
      minWidth: 140,
    },
    fontWeight: 500,
  },
  infomation: {
    fontSize: 14,
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
  },
  plan: {
    fontSize: 14,
    textTransform: 'capitalize',
  },
}));

export { useStyles };
