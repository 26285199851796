import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ emptyRows: boolean }>()((theme, { emptyRows }) => ({
  wrapperListTable: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.colors.gainsboro}`,
    borderRadius: '8px',
    '.MuiDataGrid-root': {
      borderRadius: '8px',
      '.MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-iconButtonContainer .MuiBadge-root .MuiBadge-badge':
        {
          display: 'none',
        },
    },
    '.MuiDataGrid-toolbarContainer': {
      padding: '0',
      margin: '0',
    },
  },
  dataGrid: {
    '.MuiDataGrid-footerContainer': {
      '.MuiTablePagination-root': {
        padding: '0 8px',
        [theme.breakpoints.up('md')]: {
          padding: '0 16px',
        },
      },
    },
    '.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within':
      {
        outline: ' none',
      },
    '& .MuiDataGrid-virtualScrollerContent': {
      borderTop: `1px solid ${theme.colors.gainsboro}`,
      borderBottom: `1px solid ${theme.colors.gainsboro}`,
    },
    '.MuiDataGrid-panelFooter': {
      display: 'none',
    },
    '.MuiTablePagination-selectLabel': {
      display: 'block',
    },
    '.MuiTablePagination-input': {
      display: 'inline-flex',
    },
  },
}));

export { useStyles };
