import { FC } from 'react';

import { Outlet } from 'react-router-dom';

import { WithFirebaseUser } from '~/hooks/with-firebase-auth';

const PrivateWrapper: FC = () => (
  <WithFirebaseUser>
    <Outlet />
  </WithFirebaseUser>
);

export default PrivateWrapper;
