import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  addBtn: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    marginBottom: '36px',
  },
  title: {
    fontSize: '24px',
    textTransform: 'uppercase',
  },
  role: {
    textTransform: 'capitalize',
  },
}));

export { useStyles };
