import React, { useCallback, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { FormValues, validateForm } from './form';
import {
  AdminUser,
  AdminUserRoleEnum,
  InputMaybe,
  UpdateAdminUserDocument,
  useUpdateAdminUserMutation,
} from '~/graphql/admin-api/types';
import { StyledComponentProps } from '~/types/material-ui';
import { SelectOption } from '~/types/view/common';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  open: boolean;
  onClose: () => void;
  adminUser: AdminUser;
  refechUserData?: () => Promise<any>;
  onRefetchData?: () => Promise<any>;
}

const UpdateAdminUserRoleDialog: React.FC<Props> = (props) => {
  const { open, onClose, adminUser, onRefetchData, refechUserData } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [changeRoleUser] = useUpdateAdminUserMutation({
    refetchQueries: [UpdateAdminUserDocument],
  });

  const [role, setRole] = useState(AdminUserRoleEnum.Owner);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await changeRoleUser({
          variables: {
            input: {
              adminId: adminUser.adminId as string,
              role: values.role as InputMaybe<AdminUserRoleEnum>,
            },
          },
        });
        enqueueSnackbar(t('update_successful'), { variant: 'success' });
        onClose();
        onRefetchData?.();
        refechUserData?.();
      } catch (e: any) {
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    },
    [
      changeRoleUser,
      onClose,
      onRefetchData,
      refechUserData,
      role,
      adminUser.adminId,
      t,
      enqueueSnackbar,
    ],
  );

  const adminRoleOptions: Array<SelectOption> = useMemo(() => {
    return [
      {
        value: AdminUserRoleEnum.Owner,
        label: AdminUserRoleEnum.Owner,
      },
    ];
  }, []);

  const formik = useFormik<FormValues>({
    initialValues: {
      role: AdminUserRoleEnum.Owner,
    },
    validate: validateForm(t),
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <Dialog fullWidth open={open} data-testid="change-admin-role-dialog">
      <IconButton onClick={onClose} className={classes.closeIcon} data-testid="close-button">
        <img src="/images/close_icon.svg" alt="" />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Typography variant="h5" color="primary" component="p">
            {t('edit_admin_user')}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            {t('update_admin_dialog.description')}
          </Typography>
        </DialogTitle>
        <Divider className={classes.divider} />
        <DialogContent>
          <TextField
            disabled
            autoFocus
            color="primary"
            variant="outlined"
            fullWidth
            value={adminUser.email}
            className={classes.textField}
          >
            {adminUser.email}
          </TextField>
          <TextField
            select
            name="role"
            label={t('admin_role')}
            value={formik.values.role}
            onChange={formik.handleChange}
            autoFocus
            color="primary"
            variant="outlined"
            fullWidth
            className={classes.textField}
          >
            {adminRoleOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button color="primary" variant="outlined" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={formik.isSubmitting}
            endIcon={formik.isSubmitting && <CircularProgress size={20} color="inherit" />}
          >
            {t('update')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateAdminUserRoleDialog;
