import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { compose } from 'redux';
import AccountDetailAws from './AccountDetailAws';
import AccountDetailEndpoints from './AccountDetailEndpoints';
import AccountDetailInfo from './AccountDetailInfo';
import UserLayout from '~/components/AppLayout';
import LoaderCenter from '~/components/LoaderCenter';
import { ENDPOINT_ACCOUNT_TABS } from '~/enums/common';
import { AppRoutesEnum } from '~/enums/route.enum';
import { useGetEndpointServiceUserQuery } from '~/graphql/admin-api/types';
import { withApolloClient } from '~/graphql/client';
import { withMe } from '~/hooks/use-me';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const EndpointAccountDetailTabs = (t: TFunction) => ({
  [ENDPOINT_ACCOUNT_TABS.INFO]: {
    value: 0,
    label: 'info',
  },
  [ENDPOINT_ACCOUNT_TABS.ENDPOINTS]: {
    value: 1,
    label: 'Endpoints',
  },
  [ENDPOINT_ACCOUNT_TABS.AWS]: {
    value: 2,
    label: 'aws',
  },
});

const EndpointUserDetail = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [tab, setTab] = useState(EndpointAccountDetailTabs(t)[ENDPOINT_ACCOUNT_TABS.INFO].value);
  const {
    data: endpointUserData,
    loading: loadingEnpointUserData,
    refetch: refetchData,
  } = useGetEndpointServiceUserQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        ownerUid: id ?? '',
      },
    },
  });

  const handleChangeTab = useCallback(
    (_: SyntheticEvent<Element, Event>, value: any) => {
      setTab(value);
      navigate(`${location.pathname}?tab=${value}`);
    },
    [setTab, navigate],
  );

  const renderContent = useMemo(() => {
    switch (tab) {
      case EndpointAccountDetailTabs(t)[ENDPOINT_ACCOUNT_TABS.INFO].value:
        return <AccountDetailInfo userInfoData={endpointUserData} />;
      case EndpointAccountDetailTabs(t)[ENDPOINT_ACCOUNT_TABS.ENDPOINTS].value:
        return <AccountDetailEndpoints userInfoData={endpointUserData} />;
      case EndpointAccountDetailTabs(t)[ENDPOINT_ACCOUNT_TABS.AWS].value:
        return <AccountDetailAws userInfoData={endpointUserData} />;
      default:
        return <LoaderCenter />;
    }
  }, [tab, t, endpointUserData]);

  return (
    <UserLayout>
      <div style={{ width: '100%' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Typography>{t('endpoint_service.endpoint_service')}</Typography>
          <Typography onClick={() => navigate(AppRoutesEnum.ENDPOINT_SERVICE_ACCOUNT)}>
            {t('account_page.account_page_title')}
          </Typography>
          <Typography>{endpointUserData?.getEndpointServiceUser.accountId || '-'}</Typography>
        </Breadcrumbs>
        <Box
          sx={{
            flex: 1,
            borderBottom: 1,
            borderColor: 'divider',
            marginRight: '24px',
            width: '100%',
          }}
        >
          <Tabs value={tab} onChange={handleChangeTab} className={classes.tabs}>
            {Object.values(EndpointAccountDetailTabs(t)).map((item) => (
              <Tab key={item.value} label={item.label} />
            ))}
          </Tabs>
        </Box>
        {renderContent}
      </div>
    </UserLayout>
  );
};

export default compose(withApolloClient, withMe)(EndpointUserDetail);
