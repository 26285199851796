import React, { useCallback } from 'react';
// Components
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
// Types
import AuthLayout from '../AuthLayout';
import { AppRoutesEnum } from '~/enums/route.enum';
import { useLogout } from '~/hooks/use-logout';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';
// Styles

interface Props extends StyledComponentProps<typeof useStyles> {
  code?: number;
  message?: string;
}

const Error: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { code = 500, message } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const navigate = useNavigate();
  const handleNavigate = useCallback(() => {
    navigate(AppRoutesEnum.AUTH);
  }, []);

  return (
    <AuthLayout>
      <Paper className={classes.paper}>
        <div className={classes.logo}>
          <img src="/images/logo.svg" alt="" />
        </div>
        <Typography variant="h6" className={classes.code}>
          ({code})
        </Typography>
        <Typography variant="body2" className={classes.message}>
          {message}
        </Typography>
        <div className={classes.loginButtonWrapper}>
          <Button onClick={handleNavigate}>
            <Button variant="contained" color="primary">
              {t('login_page')}
            </Button>
          </Button>
        </div>
      </Paper>
    </AuthLayout>
  );
};

export default Error;
