import { useCallback, useMemo, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import {
  DataGridProProps,
  GridColDef,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { stringOperators } from '~/common/filter-common';
import UserLayout from '~/components/AppLayout';
import CustomDataGrid from '~/components/CustomDataGird';
import {
  DATA_GRID_SORTING_ORDER,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ROWS_PER_PAGE_OPTIONS,
} from '~/constants/view';
import {
  ListDevProgramAccountsSortByEnum,
  OrderByEnum,
  useListDevProgramAccountsQuery,
} from '~/graphql/admin-api/types';
import { withApolloClient } from '~/graphql/client';
import { withMe } from '~/hooks/use-me';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const DevelopmentProgram = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t, i18n } = useTranslation();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    page: DEFAULT_PAGE,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: ListDevProgramAccountsSortByEnum.CreatedAt,
      sort: OrderByEnum.Desc.toLowerCase() as GridSortDirection,
    },
  ]);

  const {
    data: listDevelopmentData,
    loading: loadingListDevelopmentData,
    refetch: refetchListDevelopment,
  } = useListDevProgramAccountsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      sortBy: ListDevProgramAccountsSortByEnum.CreatedAt,
      orderBy: (sortModel[0]?.sort?.toUpperCase() as OrderByEnum) ?? OrderByEnum.Desc,
    },
  });

  const onPaginationModelChange = useCallback<
    NonNullable<DataGridProProps['onPaginationModelChange']>
  >(
    (model) => {
      setPaginationModel(model);
    },
    [setPaginationModel],
  );

  const onSortModelChange = useCallback<NonNullable<DataGridProProps['onSortModelChange']>>(
    (model) => {
      setSortModel(model);
    },
    [setSortModel],
  );

  const rows = useMemo(
    () =>
      (listDevelopmentData?.listDevProgramAccounts?.items || []).map((item) => ({
        accountId: item.accountId,
        firebaseUid: item.ownerUid,
        name: item.name,
        status: item.status,
        createdAt: item.createdAt,
      })),
    [listDevelopmentData?.listDevProgramAccounts?.items],
  );

  type Row = (typeof rows)[number];
  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'accountId',
        headerName: t('account_id'),
        sortable: false,
        minWidth: 200,
        flex: 1,
        filterOperators: stringOperators,
      },
      {
        field: 'firebaseUid',
        headerName: t('firebase_uid'),
        sortable: false,
        minWidth: 200,
        filterOperators: stringOperators,
      },
      {
        field: 'name',
        headerName: t('name'),
        sortable: false,
        minWidth: 200,
        filterOperators: stringOperators,
        renderCell: ({ row }) => <Typography>{row.name || '-'}</Typography>,
      },
      {
        field: 'status',
        headerName: t('status'),
        sortable: false,
        minWidth: 200,
        filterOperators: stringOperators,
        renderCell: ({ row }) => (
          <Typography>{t(`dev_program_member_status_enum.${row.status.toLowerCase()}`)}</Typography>
        ),
      },
      {
        field: ListDevProgramAccountsSortByEnum.CreatedAt,
        headerName: t('created_date'),
        minWidth: 200,
        filterable: false,
        renderCell: ({ row }) => (
          <Typography>{moment(row.createdAt).format(t('date_format'))}</Typography>
        ),
      },
    ],
    [t],
  );

  return (
    <UserLayout>
      <Breadcrumbs>
        <Typography>{t('development_program.development_program_title')}</Typography>
      </Breadcrumbs>
      <Typography className={classes.title}>
        {t('development_program.development_program_title')}
      </Typography>
      <div style={{ width: '100%' }}>
        <CustomDataGrid
          rows={loadingListDevelopmentData ? [] : rows}
          columns={columns}
          paginationMode="server"
          sortingMode="server"
          rowCount={listDevelopmentData?.listDevProgramAccounts?.pagination.totalItems || 0}
          getRowId={(row) => row.accountId}
          loading={loadingListDevelopmentData}
          pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          sortModel={sortModel}
          sortingOrder={DATA_GRID_SORTING_ORDER}
          onSortModelChange={onSortModelChange}
          pagination
          disableRowSelectionOnClick
          disableVirtualization
          disableColumnMenu
        />
      </div>
    </UserLayout>
  );
};

export default compose(withApolloClient, withMe)(DevelopmentProgram);
