import { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { stringOperators } from '~/common/filter-common';
import CustomDataGrid from '~/components/CustomDataGird';
import { GetEndpointServiceUserQuery } from '~/graphql/admin-api/types';
import { withApolloClient } from '~/graphql/client';
import { withMe } from '~/hooks/use-me';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  userInfoData?: GetEndpointServiceUserQuery;
}

const AccountDetailEndpoints = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { userInfoData } = props;
  const { t } = useTranslation();

  const rows = useMemo(
    () =>
      (userInfoData?.getEndpointServiceUser.endpoints || []).map((item) => ({
        endpointId: item.endpointId,
        apiId: item.apiId,
        resourceId: item.resourceId,
        status: item.status,
      })),
    [userInfoData?.getEndpointServiceUser.endpoints],
  );

  type Row = (typeof rows)[number];
  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'endpointId',
        headerName: t('endpoint_service.endpoint_id'),
        sortable: false,
        minWidth: 200,
        flex: 1,
        filterOperators: stringOperators,
      },
      {
        field: 'apiId',
        headerName: t('endpoint_service.api_id'),
        sortable: false,
        minWidth: 200,
        filterOperators: stringOperators,
      },
      {
        field: 'resourceId',
        headerName: t('endpoint_service.resourceId'),
        sortable: false,
        minWidth: 200,
        filterOperators: stringOperators,
      },
      {
        field: 'status',
        headerName: t('status'),
        sortable: false,
        minWidth: 200,
        filterOperators: stringOperators,
        renderCell: ({ value, row }) => {
          return (
            <Typography>{t(`endpoint_status_enum.${row.status?.toLowerCase()}`) || '-'}</Typography>
          );
        },
      },
    ],
    [t],
  );

  return (
    <div className={classes.root}>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        sortingMode="server"
        getRowId={(row) => row.resourceId}
        pagination={false}
        disableRowSelectionOnClick
        disableVirtualization
        disableColumnMenu
      />
    </div>
  );
};

export default compose(withApolloClient, withMe)(AccountDetailEndpoints);
