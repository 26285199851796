import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: 140,
  },
  languageMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0!important',
    '.MuiTypography-root': {
      marginLeft: '16px',
    },
  },
}));

export { useStyles };
