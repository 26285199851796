import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import UserLayout from '~/components/AppLayout';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const EndpointService = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();

  return (
    <UserLayout>
      <div style={{ width: '100%' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Typography>{t('endpoint_service.endpoint_service')}</Typography>
          <Typography>{t('aws_page.aws_page_title')}</Typography>
        </Breadcrumbs>
      </div>
    </UserLayout>
  );
};

export default EndpointService;
